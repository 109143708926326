import Navbar from "./component/pages/Navbar";
import "./component/scss/Main.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./component/pages/Home";
import "./App.scss";
import Form1 from "./component/pages/Form1";
import Page404 from "./component/pages/Page404";
import Pool from "./component/pages/Pool";
import Add_liquidity from "./component/pages/Add_liquidity";
import Create_lock from "./component/pages/Create_lock";
import My_token_lock from "./component/pages/My_token_lock";
import All_token_lock from "./component/pages/All_token_lock";
import My_liquidity_lock from "./component/pages/My_liquidity_lock";
import All_liquidity_lock from "./component/pages/All_liquidity_lock";
import My_token_lock_detail from "./component/pages/My_token_lock_detail";
import My_liquidity_lock_detail from "./component/pages/My_liquidity_lock_detail";
import Remove_liquidity from "./component/pages/Remove_liquidity";
import All_lock_detail from "./component/pages/All_lock_detail";
import Earning from "./component/pages/Earning";
import Create_maal_lock from "./component/pages/Create_maal_lock";
import My_lock_detail from "./component/pages/My_lock_detail";
import All_token_lock_detail from "./component/pages/All_token_lock_detail";
import { useEffect, useState } from "react";
import {
  DissconnectWallet,
  GetChainId,
  MetaMasklogin,
  WalletConnect,
  getUserAddress,
} from "./Web3/web3";
import { useStoreActions } from "easy-peasy";
import { useWeb3React } from "@web3-react/core";
// import { hooks } from "./Web3/Connector/WalletConnect";

function App() {
  const setUser = useStoreActions((action) => action.setUser);
  const { account, connector, provider } = useWeb3React();
  const [chain, setChain] = useState();

  useEffect(() => {
    const init = async () => {
      const wallet = window.localStorage.getItem("wallet");
      if (wallet) {
        await Metamask();
      }
      const id = await GetChainId();
      setChain(id);
      if (Number(id) != 7860) {
        // await window.ethereum.request({
        //   method: "wallet_switchEthereumChain",
        //   params: [{ chainId: "0x370" }], // chainId must be in hexadecimal numbers 38
        // });
      }
    };

    init();
  }, []);

  const WalletC = async () => {
    await WalletConnect();
    const add = await getUserAddress();
    setUser(add);
  };

  const Metamask = async () => {
    connector.activate();
    await MetaMasklogin();
    const add = await getUserAddress();
    window.localStorage.setItem("wallet", "wallet");
    setUser(add);
  };

  const Dissconnect = async () => {
    await DissconnectWallet();
    setUser(undefined);
    window.localStorage.removeItem("wallet");
  };

  try {
    window.ethereum.on("accountsChanged", function (accounts) {
      setUser(accounts[0]);
    });
  } catch (error) {}

  try {
    window.ethereum.on("chainChanged", function (accounts) {
      window.location.reload();
    });
  } catch (error) {}

  return (
    <div className="">
      <Router>
        <Navbar
          Dissconnect={Dissconnect}
          Metamask={Metamask}
          WalletC={WalletC}
          chain={chain}
        />
        <Routes>
        <Route
            path="/"
            element={
              <All_token_lock
                Dissconnect={Dissconnect}
                Metamask={Metamask}
                WalletC={WalletC}
              />
            }
          />
          {/* <Route
            path="/"
            element={
              <Home
                Dissconnect={Dissconnect}
                Metamask={Metamask}
                WalletC={WalletC}
              />
            }
          /> */}
          <Route
            path="/form"
            element={
              <Form1
                Dissconnect={Dissconnect}
                Metamask={Metamask}
                WalletC={WalletC}
              />
            }
          />

          <Route
            path="/maal/lock/:address"
            element={
              <All_token_lock_detail
                Dissconnect={Dissconnect}
                Metamask={Metamask}
                WalletC={WalletC}
              />
            }
          />
          <Route
            path="/create-maal-lock"
            element={
              <Create_maal_lock
                Dissconnect={Dissconnect}
                Metamask={Metamask}
                WalletC={WalletC}
              />
            }
          />
          <Route
            path="/create-lock"
            element={
              <Create_lock
                Dissconnect={Dissconnect}
                Metamask={Metamask}
                WalletC={WalletC}
              />
            }
          />
          <Route
            path="/my-token-lock"
            element={
              <My_token_lock
                Dissconnect={Dissconnect}
                Metamask={Metamask}
                WalletC={WalletC}
              />
            }
          />
          <Route
            path="/maal-lock"
            element={
              <All_lock_detail
                Dissconnect={Dissconnect}
                Metamask={Metamask}
                WalletC={WalletC}
              />
            }
          />
          <Route
            path="/token-lock"
            element={
              <All_token_lock
                Dissconnect={Dissconnect}
                Metamask={Metamask}
                WalletC={WalletC}
              />
            }
          />
          <Route
            path="/Remove-Liquidity/:pair"
            element={
              <Remove_liquidity
                Dissconnect={Dissconnect}
                Metamask={Metamask}
                WalletC={WalletC}
              />
            }
          />
          <Route
            path="/My_token_lock_detail/:index/:pair"
            element={
              <My_token_lock_detail
                Dissconnect={Dissconnect}
                Metamask={Metamask}
                WalletC={WalletC}
              />
            }
          />
          <Route
            path="/earning"
            element={
              <Earning
                Dissconnect={Dissconnect}
                Metamask={Metamask}
                WalletC={WalletC}
              />
            }
          />
          <Route
            path="/My_liquidity_lock_detail/:index/:pair"
            element={
              <My_liquidity_lock_detail
                Dissconnect={Dissconnect}
                Metamask={Metamask}
                WalletC={WalletC}
              />
            }
          />
          <Route
            path="/my-liquidity-lock"
            element={
              <My_liquidity_lock
                Dissconnect={Dissconnect}
                Metamask={Metamask}
                WalletC={WalletC}
              />
            }
          />
          <Route
            path="/my-lock-detail"
            element={
              <My_lock_detail
                Dissconnect={Dissconnect}
                Metamask={Metamask}
                WalletC={WalletC}
              />
            }
          />
          <Route
            path="/liquid-lock"
            element={
              <All_liquidity_lock
                Dissconnect={Dissconnect}
                Metamask={Metamask}
                WalletC={WalletC}
              />
            }
          />

          <Route
            path="/pool"
            element={
              <Pool
                Dissconnect={Dissconnect}
                Metamask={Metamask}
                WalletC={WalletC}
              />
            }
          />
          <Route
            path="/Add_liquidity"
            element={
              <Add_liquidity
                Dissconnect={Dissconnect}
                Metamask={Metamask}
                WalletC={WalletC}
              />
            }
          />
          <Route
            path="*"
            element={
              <Page404
                Dissconnect={Dissconnect}
                Metamask={Metamask}
                WalletC={WalletC}
              />
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
