import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiFillLock } from "react-icons/ai";
import { BiTimer } from "react-icons/bi";
import { ERC_TOKEN } from "../../Web3/Tokens";
import { useStoreState } from "easy-peasy";
import Warnings from "./Warnings";
import My_lock_tab from "./My_lock_tab";
import {
  convertToLowercase,
  get_ERC_20_Contract,
  get_Locker_Contract,
  get_Maal_Factory_Locker_Contract,
  get_Maal_Locker_Contract,
  towie,
} from "../../Web3/web3";
import isEthereumAddress from "validator/lib/isEthereumAddress";
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from "@mui/material";
import Footer from "./Footer";

const notify = (msg) =>
  toast.success(msg, {
    position: toast.POSITION.TOP_RIGHT,
  });

function My_lock() {
  const user = useStoreState((state) => state.user);
  const [open, setOpen] = useState();
  const [alltokenLocked, setAllTokenLocked] = useState();
  const [tokentokenLocked, setTotalTokenLocked] = useState();
  const [mappingLP, setMappingLP] = useState([]);
  const [amount, setAmount] = useState();

  useEffect(() => {
    const init = async () => {
      const lpContract = await get_Maal_Factory_Locker_Contract();
      const contractLocked = await lpContract.methods.getWallets(user).call();
      const lockedDetails = [];
      for (let i = 0; i < contractLocked.length; i++) {
        const lockerContrat = await get_Maal_Locker_Contract(contractLocked[i]);
        let contractClaimedBalance = await lockerContrat.methods
          .claimedBalance()
          .call();
        let info = await lockerContrat.methods.getInfo().call();
        const isExpired = await lockerContrat.methods.lockExpired().call();
        if (true) {
          info.expired = isExpired;
          info.address = contractLocked[i];
          info.balance = Number(contractClaimedBalance);

          lockedDetails.push(info);
        }
      }
      setAllTokenLocked(lockedDetails);
    };
    init();
  }, [user, open]);

  console.log("Con", alltokenLocked);

  const sliceString = (str) => {
    return str?.length > 25 ? `${str.substring(0, 6)}...${str.substr(36)}` : ``;
  };

  const search = async (address) => {
    try {
      if (address == "") {
        setAllTokenLocked(mappingLP);
        return;
      }
      const valid = isEthereumAddress(
        address === "0x0000000000000000000000000000000000000000" ? "" : address
      );
      if (valid) {
        const result = alltokenLocked.filter(function (element) {
          return (
            convertToLowercase(element.address) == convertToLowercase(address)
          );
        });
        setAllTokenLocked(result);
      } else {
        // notify("Invalid address")
        setAllTokenLocked([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const DepositMaal = async (address, amount) => {
    try {
      setOpen(true);
      const lockerContrat = await get_Maal_Locker_Contract(address);
      const a = await towie(amount);
      const data = await lockerContrat.methods
        .deposit()
        .send({ from: user, value: a });
      if (data.status) {
        notify("Token Locked");
        setOpen(false);
      }
    } catch (error) {
      console.log(error);
      setOpen(false);
    }
  };

  const claimMaal = async (address) => {
    try {
      setOpen(true);
      const lockerContrat = await get_Maal_Locker_Contract(address);
      // const data = await lockerContrat.methods.claim().call();
      // console.log('data', data)
      const data = await lockerContrat.methods.claim().send({ from: user });
      console.log(data);
      if (data.status) {
        notify("Claimed success");
        setOpen(false);
      }
    } catch (error) {
      console.log(error);
      setOpen(false);
    }
  };

  return (
    <div>
      <div className=" background_image ">
        {/*<div className=""><Warnings /></div> */}
        <div className="container-kws">
          <div className="bg_br_clr  b-r-15 m-t-4 m-b-5  pa-1_5">
            <My_lock_tab />

            <div className="grid m-y-3">
              {alltokenLocked &&
                alltokenLocked.map((res) => {
                  return (
                    <div className=" ">
                      <div className="p-x-2 p-y-1 b-r-15 bg_btn_clr o_clr t-a-c">
                        <Link to={`/maal/lock/${res.address}`}>
                          <div className="d-f a-i-c j-c-s-b m-y-1">
                            <div className="f-s-1_25">Owner :</div>
                            <div className="">{sliceString(res["0"])}</div>
                          </div>
                          <div className="d-f a-i-c j-c-s-b m-y-1">
                            <div className="f-s-1_25">Start Wait Time :</div>
                            <div className="">
                              {(res["1"] / 86400).toFixed(3)} days
                            </div>
                          </div>
                          <div className="d-f a-i-c j-c-s-b m-y-1">
                            <div className="f-s-1_25">
                              Number Of Vesting Periods :
                            </div>
                            <div className="">{res["2"]}</div>
                          </div>
                          {res["3"] == "0" ? (
                            ""
                          ) : (
                            <div className="d-f a-i-c j-c-s-b m-y-1">
                              <div className="f-s-1_25">
                                Vesting Period Duration :
                              </div>
                              <div className="">
                                {(res["3"] / 86400).toFixed(3)} days
                              </div>
                            </div>
                          )}
                          <div className="d-f a-i-c j-c-s-b m-y-1">
                            <div className="f-s-1_25">Percent Per Claim :</div>
                            <div className="">{res["4"]} %</div>
                          </div>
                          <div className="d-f a-i-c j-c-s-b m-y-1">
                            <div className="f-s-1_25">Remaining Balance :</div>
                            <div className="">
                              {Number(
                                res["5"] / 10 ** 18 - res.balance / 10 ** 18
                              ).toFixed(3)}{" "}
                              MAAL
                            </div>
                          </div>
                        </Link>
                        <div className="m-y-1">
                          <input
                            type="text"
                            className="w-100 b-1 b-r-5 pa-0_5 b-c-t c-w"
                            placeholder="amount"
                            onChange={(e) => setAmount(e.target.value)}
                          />
                        </div>
                        <div className="d-f a-i-c j-c-s-e m-t-2 m-b-1">
                          <button
                            className="b-c-t b-n bg_o_clr max_clr w-7 p-y-0_5 b-r-15"
                            onClick={() => DepositMaal(res.address, amount)}
                          >
                            Deposit
                          </button>
                          <button
                            className="b-c-t b-n bg_o_clr max_clr w-7 p-y-0_5 b-r-15"
                            onClick={() => claimMaal(res.address)}
                          >
                            Claim
                          </button>
                        </div>
                        <Link to={`/maal/lock/${res.address}`}>
                          {" "}
                          <div className="">
                            <button className="bg_o_clr max_clr w-s-n-w p-x-2  p-y-0_5 w-100 b-n b-r-15  m-y-1 t-t-u t-a-c">
                              View Detail
                            </button>
                          </div>{" "}
                        </Link>
                      </div>
                    </div>
                  );
                })}
             
            </div>
            {!alltokenLocked ? <div className="t-a-c c-w p-y-5"> Loading... </div> : alltokenLocked.length > 0 ? (
                ""
              ) : (
                <div className="t-a-c c-w p-y-5"> No data to show </div>
              )}
          </div>
        </div>
        <Footer />
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default My_lock;
