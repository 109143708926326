import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiFillLock } from "react-icons/ai";
import { BiTimer } from "react-icons/bi";
import { ERC_TOKEN } from "../../Web3/Tokens";
import { useStoreState } from "easy-peasy";
import Warnings from "./Warnings";
import {
  convertToLowercase,
  get_ERC_20_Contract,
  get_Locker_Contract,
  get_Maal_Factory_Locker_Contract,
  get_Maal_Locker_Contract,
  towie,
} from "../../Web3/web3";
import isEthereumAddress from "validator/lib/isEthereumAddress";
import { toast } from "react-toastify";
import All_lock_tab from "./All_lock_tab";
import Footer from "./Footer";

const notify = (msg) =>
  toast.success(msg, {
    position: toast.POSITION.TOP_RIGHT,
  });

function My_lock() {
  const user = useStoreState((state) => state.user);
  const [alltokenLocked, setAllTokenLocked] = useState();
  const [tokentokenLocked, setTotalTokenLocked] = useState();
  const [mappingLP, setMappingLP] = useState([]);
  const [open, setOpen] = useState();

  useEffect(() => {
    const init = async () => {
      const lpContract = await get_Maal_Factory_Locker_Contract();
      const contractLocked = await lpContract.methods.currentWalletId().call();
      const lockedDetails = [];
      for (let i = Number(contractLocked); i > 0; i--) {
        const contractLocked = await lpContract.methods
          .timeLockedWallets(i)
          .call();
        const lockerContrat = await get_Maal_Locker_Contract(contractLocked);
        let info = await lockerContrat.methods.getInfo().call();
        const isExpired = await lockerContrat.methods.lockExpired().call();
        if (true) {
          info.expired = isExpired;
          info.address = contractLocked;
          lockedDetails.push(info);
        }
      }
      // console.log(lockedDetails)
      setAllTokenLocked(lockedDetails);
    };
    init();
  }, [user]);

  const sliceString = (str) => {
    return str?.length > 25 ? `${str.substring(0, 6)}...${str.substr(36)}` : ``;
  };

  const search = async (address) => {
    try {
      if (address == "") {
        setAllTokenLocked(mappingLP);
        return;
      }
      const valid = isEthereumAddress(
        address === "0x0000000000000000000000000000000000000000" ? "" : address
      );
      if (valid) {
        const result = alltokenLocked.filter(function (element) {
          return (
            convertToLowercase(element.address) == convertToLowercase(address)
          );
        });
        setAllTokenLocked(result);
      } else {
        // notify("Invalid address")
        setAllTokenLocked([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className=" background_image ">
        <div className="">
          <Warnings />
        </div>
        <div className="container-kws">
          <div className="bg_br_clr  b-r-15 m-t-4 m-b-5  pa-1_5">
            <All_lock_tab />

            <div className="row m-t-4">
              {alltokenLocked &&
                alltokenLocked.map((res) => {
                  return (
                    <div className="col-12 col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xxl-4 m-y-1">
                      <Link to={`/maal/lock/${res.address}`}>
                        <div className="p-x-2 p-y-1 b-r-15 bg_btn_clr o_clr t-a-c">
                          <div className="d-f a-i-c j-c-s-b m-y-1">
                            <div className="f-s-1_25">Owner :</div>
                            <div className="">{sliceString(res["0"])}</div>
                          </div>
                          <div className="d-f a-i-c j-c-s-b m-y-1">
                            <div className="f-s-1_25">Start Wait Time :</div>
                            <div className="">
                              {(res["1"] / 86400).toFixed(3)} days
                            </div>
                          </div>
                          <div className="d-f a-i-c j-c-s-b m-y-1">
                            <div className="f-s-1_25">
                              Number Of Vesting Periods :
                            </div>
                            <div className="">{res["2"]}</div>
                          </div>
                          {res["3"] == 0 ? (
                            ""
                          ) : (
                            <div className="d-f a-i-c j-c-s-b m-y-1">
                              <div className="f-s-1_25">
                                Vesting Period Duration :
                              </div>
                              <div className="">
                                {(res["3"] / 86400).toFixed(3)} days
                              </div>
                            </div>
                          )}
                          <div className="d-f a-i-c j-c-s-b m-y-1">
                            <div className="f-s-1_25">Percent Per Claim :</div>
                            <div className="">{res["4"]} %</div>
                          </div>
                          <div className="d-f a-i-c j-c-s-b m-y-1">
                            <div className="f-s-1_25">Total Balance :</div>
                            <div className="">{Number(res["5"] / 10 ** 18).toFixed(2)} MAAL</div>
                          </div>
                          <div className="">
                            <button className="bg_o_clr max_clr w-s-n-w p-x-2  p-y-0_5 w-100 b-n b-r-15  m-y-1 t-t-u t-a-c">
                              View Detail
                            </button>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}

              {!alltokenLocked ? <div className="t-a-c c-w p-y-5"> Loading... </div> : alltokenLocked.length > 0 ? (
                ""
              ) : (
                <div className="t-a-c c-w p-y-5"> No data to show </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default My_lock;
