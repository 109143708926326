import React, { useEffect, useLayoutEffect, useState } from "react";

import { ImCross } from "react-icons/im";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";
import { AiOutlineMenu, AiTwotoneCheckCircle } from "react-icons/ai";

import Modal from "react-bootstrap/Modal";
import meta from "../Image/meta.png";

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import maal from "../Image/maal.png";

import tick from "../Image/tick.png";

import "animate.css";
import eth from "../Image/eth.png";

import logo from "../Image/ramz.png";
import { Link } from "react-router-dom";
import bnb from "../Image/bnb.png";

import Wallet from "../Svg/walletconnect.svg";
import { RxCross2 } from "react-icons/rx";
import { BsFillCircleFill } from "react-icons/bs";
import { useStoreState } from "easy-peasy";

function Navbar({ Dissconnect, Metamask, WalletC, chain }) {
  const [show, setShow] = useState(false);
  const [select, setselects] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ---------dark---------mode----------------

  useLayoutEffect(() => {
    const data = window.localStorage.getItem("theme");
    if (data === "dark-theme") {
      setTheme("dark-theme");
    } else {
      setTheme("light-theme");
    }
  }, []);

  const [theme, setTheme] = useState("light-theme");
  const toggleTheme = () => {
    theme === "dark-theme" ? setTheme("light-theme") : setTheme("dark-theme");
    window.localStorage.setItem(
      "theme",
      theme === "dark-theme" ? "light-theme" : "dark-theme"
    );
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  const user = useStoreState((state) => state.user);
  const connectMetamask = useStoreState((state) => state.connectMetamask);
  const connectWalletConnect = useStoreState(
    (state) => state.connectWalletConnect
  );

  const sliceaddress = (add) => {
    if (typeof add === "string") {
      const first = add.slice(0, 5);
      const seconds = add.slice(36);
      return first + "..." + seconds;
    }
  };

  return (
    <>
      <div className="">
        <div className="container-kws">
          <div className="  navbar12  d-f a-i-c j-c-s-b p-y-1">
            <div className="">
              <ul className="l-s-t-n d-f a-i-c ">
                <li className="m-r-2 f-s-1_25 f-w-600">
                  <img
                    src={logo}
                    alt=""
                    className="logo animate__animated animate__rubberBand w-4"
                  />
                </li>
                <a target="_blank" href="https://app.ramzswap.com/#/swap" rel="noreferrer">
                  <li className="m-r-2 c-p   m-q-b-d-n">Swap</li>
                </a>
                <a target="_blank" href="https://app.ramzswap.com/#/pool/v2" rel="noreferrer">
                  <li className="m-r-2 c-p   m-q-b-d-n">Pools</li>
                </a>

                <li className="m-r-2 c-p   m-q-b-d-n">
                  {" "}
                  <Dropdown className="b-r-5">
                    <Dropdown.Toggle className="b-c-t b-n pa-0  b-r-5">
                      Lock
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="b-n p-y-0 ">
                      <Link to="/create-lock">
                        <Dropdown.Item
                          className="drop_hover bg_btn_clr_drop b-r-t-5 p-y-0_5"
                          href="createlock"
                        >
                          Create Lock
                        </Dropdown.Item>
                      </Link>

                      <Link to="/create-maal-lock">
                        <Dropdown.Item
                          className="drop_hover bg_btn_clr_drop  p-y-0_5"
                          href="createmaallock"
                        >
                          Create Maal Lock
                        </Dropdown.Item>
                      </Link>
                      <Link to="/my-token-lock">
                        <Dropdown.Item
                          className="drop_hover bg_btn_clr_drop p-y-0_5"
                          href="#/action-2"
                        >
                          My Locks
                        </Dropdown.Item>
                      </Link>
                      <Dropdown.Item
                        className="drop_hover bg_btn_clr_drop b-r-b-5 p-y-0_5"
                        href="/token-lock"
                      >
                        All Locks
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </div>
            <div className=" c-p ">
              <ul className="l-s-t-n d-f a-i-c ">
                <li className="m-l-2 c-p   m-q-b-d-n">
                  <div className="">
                    <div
                      className="d-f a-i-c c-p o_clr f-w-600 w-s-n-w"
                      onClick={() => setselects(!select)}
                    >
                      <div className="">
                        <img
                          src="https://bafkreidwbojuklfg6ey5ofzeguluvwe554av3m7qhlom5azbs5is6yraoa.ipfs.nftstorage.link/"
                          className="w-1_5 m-r-0_5 b-r-50"
                        />
                        &nbsp;{" "}
                        {chain == 786
                          ? "Maal mainnet"
                          : chain == 7860
                          ? "Maal testnet"
                          : "___"}
                      </div>
                      <div className="ps-r">
                        {/* <div className="">
                          <MdKeyboardArrowDown className="f_o_clr b-c-t  f-s-1_5" />
                        </div> */}
                        {/* {select ? (
                          <div className="ps-a r_0 t_7 bg_btn_clr p-x-1  t-100 b-r-5">
                            <div className="l-s-t-n bg_btn_clr w-17 w-s-n-w">
                              <div className="m-y-1  w-s-n-w bg_btn_clr">
                                <div className="d-f a-i-c j-c-s-b">
                                  <div>
                                    {" "}
                                    <img
                                      src="https://ipfs.io/ipfs/QmXCRTnM7N8HefWCJjpBkio4z1wA6J5SS6921mKtzBV4hv?filename=favicon.png"
                                      className="w-1_5 m-r-0_5 b-r-50"
                                    />{" "}
                                    &nbsp;{" "}
                                    <span className="m-r-1 t-t-u">
                                      maal main net
                                    </span>
                                  </div>
                                  <div>
                                    {" "}
                                 
                                  </div>
                                </div>
                              </div>
                              <div className="m-y-1  w-s-n-w bg_btn_clr">
                                <div className="d-f a-i-c j-c-s-b">
                                  <div>
                                    {" "}
                                    <img
                                      src="https://ipfs.io/ipfs/QmXCRTnM7N8HefWCJjpBkio4z1wA6J5SS6921mKtzBV4hv?filename=favicon.png"
                                      className="w-1_5 m-r-0_5 b-r-50"
                                    />{" "}
                                    &nbsp;{" "}
                                    <span className="m-r-1 t-t-u">
                                      maal test net
                                    </span>
                                  </div>
                                  <div>
                                    {" "}
                                    <BsFillCircleFill className="f_green c_green" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )} */}
                      </div>
                    </div>
                  </div>
                </li>

                {/* <li className="m-l-2 c-pq ">
              <span className="" onClick={() => toggleTheme()}>
                {theme === "dark-theme" ? "Light mode" : "Dark mode"}
              </span>
            </li> */}
                <li className="m-l-2 c-pq ">
                  {" "}
                  <Button
                    variant="primary"
                    onClick={() => {
                      if (user) {
                        Dissconnect();
                        return;
                      }
                      handleShow3();
                    }}
                    className="pa-0_5 c-i b-c-i b-r-20 f-s-1_25 p-x-1 bg_o_clr f-w-600 max_clr b-n"
                  >
                    {user ? sliceaddress(user) : "Connect"}
                  </Button>
                </li>
                <li className="m-l-2 c-p   m-q-a-d-n ">
                  {" "}
                  <Button onClick={handleShow2} className=" b-c-t c-i b-n pa-0">
                    <AiOutlineMenu className="f-s-1_5" />
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* ---------------mobile-navbar---------------------------------- */}

      <Offcanvas
        show={show3}
        onHide={handleClose3}
        className="pa-1 bg_btn_clr gray_clr f-s-1_25"
        placement="end"
        name="end"
      >
        <Offcanvas.Body className=" ">
          <div className="d-f a-i-c j-c-s-b m-b-2">
            {" "}
            <div className="o_clr"> Connect a wallet</div>
            <div className="">
              {" "}
              <RxCross2
                className="f-s-1_5 c-p f_o_clr o_clr "
                onClick={handleClose3}
              />
            </div>
          </div>

          <div
            className="m-y-1 c-p w-100 bg_o_clr max_clr b-r-15 d-f pa-1 a-i-c"
            onClick={() => {
              Metamask();
              handleClose3();
            }}
          >
            <div className="">
              <img src={meta} alt="" className="m-r-1 w-2" />{" "}
            </div>
            <div className="">Metamask</div>
          </div>
          <div
            className="m-y-1 c-p w-100 bg_o_clr max_clr b-r-15 d-f pa-1 a-i-c"
            onClick={() => {
              WalletC();
              handleClose3();
            }}
          >
            <div className="">
              <img src={Wallet} alt="" className="m-r-1 w-2" />{" "}
            </div>
            <div className="">Wallet connect</div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {/* ---------------mobile-navbar---------------------------------- */}

      <Offcanvas
        show={show2}
        onHide={handleClose2}
        className="pa-1 bg_btn_clr gray_clr f-s-1_25"
      >
        <Offcanvas.Body className=" ">
          <ul className="l-s-t-n  ">
            <li className="m-r-2 f-s-1_25 f-w-600 m-b-2">
              <div className="d-f a-i-c j-c-s-b">
                <div className="">
                  <img
                    src={logo}
                    alt=""
                    className="logo animate__animated animate__rubberBand w-4"
                  />
                </div>{" "}
                <RxCross2
                  className="f-s-1_5 c-p f_o_clr  "
                  onClick={handleClose2}
                />
              </div>

              {/* <img
                src={logo}
                alt=""
                className="logo animate__animated animate__rubberBand"
              /> */}
            </li>
            <Link to="/">
              <li className="m-r-2 c-p m-y-1   " onClick={handleClose2}>
                Swap
              </li>
            </Link>
            <Link to="/pool">
              <li className="m-r-2 c-p m-y-1   " onClick={handleClose2}>
                Pools
              </li>
            </Link>

            <li className="m-r-2 c-p m-y-1   ">
              {" "}
              <Dropdown className="b-r-5">
                <Dropdown.Toggle className="b-c-t b-n pa-0  b-r-5">
                  Lock
                </Dropdown.Toggle>

                <Dropdown.Menu className="b-n p-y-0 ">
                  <Link to="/create-lock">
                    <Dropdown.Item
                      className="drop_hover bg_btn_clr_drop b-r-t-5 p-y-0_5"
                      href="createlock"
                      onClick={handleClose2}
                    >
                      Create Lock
                    </Dropdown.Item>
                  </Link>

                  <Link to="/create-maal-lock">
                    <Dropdown.Item
                      className="drop_hover bg_btn_clr_drop  p-y-0_5"
                      href="createmaallock"
                      onClick={handleClose2}
                    >
                      Create Maal Lock
                    </Dropdown.Item>
                  </Link>
                  <Link to="/my-token-lock">
                    <Dropdown.Item
                      className="drop_hover bg_btn_clr_drop p-y-0_5"
                      href="#/action-2"
                      onClick={handleClose2}
                    >
                      My Locks
                    </Dropdown.Item>
                  </Link>
                  <Dropdown.Item
                    className="drop_hover bg_btn_clr_drop b-r-b-5 p-y-0_5"
                    href="/token-lock"
                    onClick={handleClose2}
                  >
                    All Locks
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Navbar;
