import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AiFillEdit, AiOutlineCopy } from "react-icons/ai";
import {
  get_ERC_20_Contract,
  get_Locker_Contract,
  towie,
} from "../../Web3/web3";
import { useStoreState } from "easy-peasy";
import Countdown from "react-countdown";
import { Modal } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import { DatePicker } from "antd";
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from "@mui/material";
import Footer from "./Footer";

const notify = (msg) =>
  toast.success(msg, {
    position: toast.POSITION.TOP_LEFT,
  });

function My_lock() {
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [show4, setShow4] = useState(false);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [show5, setShow5] = useState(false);

  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const { pair, index } = useParams();
  const user = useStoreState((state) => state.user);
  const [open, setOpen] = useState();
  const [tokenName, setTokenName] = useState();
  const [tokenSymbol, setTokenSymbol] = useState();
  const [tokenLockerDetails, setTokenLockedDetails] = useState({});
  const [lockID, setLockID] = useState();
  const [newDiscription, setNewDiscription] = useState();
  const [newOwner, setNewOwner] = useState();
  const [newAmount, setNewAmount] = useState();
  const [newDate, setNewDate] = useState();
  const [unlocked, setUnlocked] = useState(false);

  useEffect(() => {
    const init = async () => {
      const TokenContract = await get_ERC_20_Contract(pair);
      const tokenName = await TokenContract.methods.name().call();
      const tokenSymbol = await TokenContract.methods.symbol().call();
      setTokenName(tokenName);
      setTokenSymbol(tokenSymbol);

      const LockerContract = await get_Locker_Contract();
      const lockname = await LockerContract.methods
        .normalLocksForUser(user)
        .call();

      console.log("Lock tokenInfo", index, lockname);
      if (lockname.length == 0) {
        setUnlocked(true);
      }
      setLockID(lockname[index][0]);
      setTokenLockedDetails({
        title: lockname[index][10],
        amount: lockname[index][3] / 10 ** 18,
        Owner: lockname[index][2],
        lockdata: lockname[index][4],
        unlockdate: lockname[index][5],
      });
    };
    init();
  }, [user, pair, open]);

  const updateDiscription = async () => {
    try {
      setOpen(true);
      const LockerContract = await get_Locker_Contract();
      const data = await LockerContract.methods
        .editLockDescription(lockID, newDiscription)
        .send({ from: user });
      if (data.status) {
        setOpen(false);
        notify("Success");
      }
    } catch (error) {
      setOpen(false);
    }
  };

  const updateLocker = async () => {
    try {
      setOpen(true);
      const a = await towie(tokenLockerDetails.amount);
      console.log(a, tokenLockerDetails.amount);
      const LockerContract = await get_Locker_Contract();

      const data = await LockerContract.methods
        .editLock(lockID, a, newDate)
        .send({ from: user });
      if (data.status) {
        setOpen(false);
        notify("Success");
      }
    } catch (error) {
      setOpen(false);
    }
  };

  const TransferOwnerShip = async () => {
    try {
      setOpen(true);
      const LockerContract = await get_Locker_Contract();
      const data = await LockerContract.methods
        .transferLockOwnership(lockID, newOwner)
        .send({ from: user });
      if (data.status) {
        setOpen(false);
        notify("Success");
      }
    } catch (error) {
      setOpen(false);
    }
  };

  const RenonceOwnerShip = async () => {
    try {
      setOpen(true);
      const LockerContract = await get_Locker_Contract();
      const data = await LockerContract.methods
        .renounceLockOwnership(lockID)
        .send({ from: user });
      if (data.status) {
        setOpen(false);
        notify("Success");
      }
    } catch (error) {
      setOpen(false);
    }
  };

  const UnlockLock = async () => {
    try {
      setOpen(true);
      const LockerContract = await get_Locker_Contract();
      const data = await LockerContract.methods
        .unlock(lockID)
        .send({ from: user });
      if (data.status) {
        setOpen(false);
        notify("Success");
      }
    } catch (error) {
      setOpen(false);
    }
  };

  const sliceString = (str) => {
    return str?.length > 25 ? `${str.substring(0, 6)}...${str.substr(36)}` : ``;
  };

  const setTimeLock = (value) => {
    const timemiliseconds = new Date(value).getTime();
    const timeInsecons = Number(timemiliseconds / 1000).toFixed(0);
    setNewDate(timeInsecons);
  };

  return (
    <div>
      <div className=" background_image ">
        <div className="container-kws m-b-7">
          <div className="bg_br_clr  b-r-15 m-t-4   pa-1_5">
            <div className="m-b-1 f-w-600 t-a-c">Unlock in</div>
            <div className="d-f a-i-c j-c-c">
              <div className="pa-0_5 m-r-0_5 b-r-5 bg_o_clr max_clr">
                {" "}
                {tokenLockerDetails.unlockdate &&
                Number(tokenLockerDetails.unlockdate) >
                  new Date().getTime() / 1000 ? (
                  <Countdown
                    date={Number(tokenLockerDetails.unlockdate) * 1000}
                  />
                ) : (
                  "00:00:00"
                )}
              </div>
              {/* <div className="pa-0_5 m-r-0_5 b-r-5 bg_o_clr max_clr">20</div>
              <div className="pa-0_5 m-r-0_5 b-r-5 bg_o_clr max_clr">57</div>
              <div className="pa-0_5 m-r-0_5 b-r-5 bg_o_clr max_clr">29</div> */}
            </div>
          </div>
          <div className="bg_br_clr  b-r-15 m-t-2   pa-1_5">
            <div className="f-s-1_25 f-w-600">Token info</div>
            <hr />
            <div className="d-f a-i-c j-c-s-b m-y-1">
              <div className="">Token Address</div>
              <div className="o_clr">
                {sliceString(pair)} <AiOutlineCopy className="f_o_clr c-p" />
              </div>
            </div>
            <div className="d-f a-i-c j-c-s-b m-y-1">
              <div className="">Token Name</div>
              <div className="">{tokenName}</div>
            </div>
            <div className="d-f a-i-c j-c-s-b m-y-1">
              <div className="">Token Symbol</div>
              <div className="o_clr">{tokenSymbol}</div>
            </div>
            <div className="d-f a-i-c j-c-s-b m-y-1">
              <div className="">Quote Token</div>
              <div className="o_clr">Wrapped MAAL</div>
            </div>
            <div className="d-f a-i-c j-c-s-b m-y-1">
              <div className="">Dex</div>
              <div className="o_clr">RamzSwap</div>
            </div>
          </div>
          <div className="bg_br_clr  b-r-15 m-t-2   pa-1_5">
            <div className="f-s-1_25 f-w-600">Lock info</div>
            <hr />
            {!unlocked ? (
              <div className="d-f a-i-c j-c-s-b m-y-1">
                <div className="">Title</div>
                <div className="">
                  {tokenLockerDetails.title}{" "}
                  <AiFillEdit
                    className="f_0_clr c-p"
                    onClick={() => handleShow5()}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="d-f a-i-c j-c-s-b m-y-1">
              <div className="">Total Amount Locked</div>
              <div className="">
                {unlocked ? "0" : tokenLockerDetails.amount}
              </div>
            </div>
            <div className="d-f a-i-c j-c-s-b m-y-1">
              <div className="">Total Value Locked</div>
              <div className="">{unlocked ? "0" : "$3,3357"}</div>
            </div>
            {!unlocked ? (
              <div className="d-f a-i-c j-c-s-b m-y-1">
                <div className="">Owner</div>
                <div className="o_clr">
                  {sliceString(tokenLockerDetails.Owner)}{" "}
                  <AiOutlineCopy className="f_o_clr c-p" />
                </div>
              </div>
            ) : (
              ""
            )}
            {!unlocked ? (
              <div className="d-f a-i-c j-c-s-b m-y-1">
                <div className="">Lock Date</div>
                <div className="">
                  {new Date(
                    Number(tokenLockerDetails.lockdata) * 1000
                  ).toDateString()}
                </div>
              </div>
            ) : (
              ""
            )}
            {!unlocked ? (
              <div className="d-f a-i-c j-c-s-b m-y-1">
                <div className="">Unlock Date</div>
                <div className="">
                  {new Date(
                    Number(tokenLockerDetails.unlockdate) * 1000
                  ).toDateString()}
                </div>
              </div>
            ) : (
              ""
            )}
            {!unlocked ? (
              <div className="m-q-d-f a-i-c j-c-c m-t-1 ">
                <button
                  className="b-c-t p-y-0_5 p-x-1  bg_btn_clr btn_clr   b-r-5 m-y-0_5 b-n m-q-m-r-2 m-q-w-100"
                  onClick={() => handleShow2()}
                >
                  Transfer Lock Ownership
                </button>
                <button
                  className="b-c-t p-y-0_5 p-x-1  bg_btn_clr btn_clr   b-r-5 m-y-0_5 b-n m-q-m-r-2 m-q-w-100"
                  onClick={() => RenonceOwnerShip()}
                >
                  Renounce Lock Ownership
                </button>
                <button
                  className="b-c-t p-y-0_5 p-x-1  bg_btn_clr btn_clr   b-r-5 m-y-0_5 b-n m-q-m-r-2 m-q-w-100"
                  onClick={() => handleShow4()}
                >
                  Update
                </button>
                <button
                  className={`b-c-t p-y-0_5 p-x-1   bg_btn_clr btn_clr   b-r-5 m-y-0_5 b-n m-q-w-100  ${
                    Number(tokenLockerDetails.unlockdate) * 1000 >
                    new Date().getTime()
                      ? "c-n-d o-50"
                      : unlocked
                      ? "c-n-d o-50"
                      : ""
                  }`}
                  onClick={() => UnlockLock()}
                  disabled={
                    Number(tokenLockerDetails.unlockdate) * 1000 >
                    new Date().getTime()
                      ? true
                      : false
                  }
                >
                  {unlocked ? "Unlocked" : "Unlock"}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <Footer />
      </div>

      {/* ------------Transfer Lock Ownership--------- */}

      <Modal show={show2} onHide={handleClose2} className="">
        <div className="bg_br_clr b-r-10">
          <Modal.Header className="d-f a-i-c j-c-s-b">
            <Modal.Title className="bg_br_clr">Update Locker</Modal.Title>
            <RxCross2 onClick={handleClose2} className="c-p" />
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="">
              {/* <input
                type="text"
                className="input b-c-t w-100 b-r-15 pa-0_5 m-y-1"
                placeholder=""
              />{" "} */}
              <input
                type="text"
                className="input b-c-t w-100 b-r-15 pa-0_5 m-b-1"
                placeholder="New owner address"
                onChange={(e) => setNewOwner(e.target.value)}
              />{" "}
              <button
                className="b-c-t p-y-0_5 p-x-1  bg_btn_clr btn_clr   b-r-5 m-y-0_5 b-n m-q-m-r-2 m-q-w-100"
                onClick={() => TransferOwnerShip()}
              >
                Submit
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* -----------Renounce Lock Ownership
-------- */}
      <Modal show={show3} onHide={handleClose3} className="">
        <div className="bg_br_clr b-r-10">
          <Modal.Header className="d-f a-i-c j-c-s-b">
            <Modal.Title className="bg_br_clr">Update Locker</Modal.Title>
            <RxCross2 onClick={handleClose3} className="c-p" />
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="">
              <input
                type="text"
                className="input b-c-t w-100 b-r-15 pa-0_5 m-y-1"
                placeholder="abc"
              />{" "}
              <input
                type="text"
                className="input b-c-t w-100 b-r-15 pa-0_5 m-b-1"
                placeholder="abc"
              />{" "}
              <button className="b-c-t p-y-0_5 p-x-1  bg_btn_clr btn_clr   b-r-5 m-y-0_5 b-n m-q-m-r-2 m-q-w-100">
                Submit
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      {/* ------------Update----------- */}
      <Modal show={show4} onHide={handleClose4} className="">
        <div className="bg_br_clr b-r-10">
          <Modal.Header className="d-f a-i-c j-c-s-b">
            <Modal.Title className="bg_br_clr">Update Locker</Modal.Title>
            <RxCross2 onClick={handleClose4} className="c-p" />
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="">
              {/* <input
                type="text"
                className="input b-c-t w-100 b-r-15 pa-0_5 m-y-1"
                placeholder="amount"
                onChange={(e)=>setNewAmount(e.target.value)}
              />{" "} */}
              <div className="m-b-2">
                <DatePicker
                  showTime
                  onOk={setTimeLock}
                  className="b-c-t  w-100 b-r-15  pa-0_5 datepicker"
                />
              </div>
              <button
                className="b-c-t p-y-0_5 p-x-1  bg_btn_clr btn_clr   b-r-5 m-y-0_5 b-n m-q-m-r-2 m-q-w-100"
                onClick={() => updateLocker()}
              >
                Submit
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      {/* ------------Edit----------- */}
      <Modal show={show5} onHide={handleClose5} className="">
        <div className="bg_br_clr b-r-10">
          <Modal.Header className="d-f a-i-c j-c-s-b">
            <Modal.Title className="bg_br_clr">Update Locker</Modal.Title>
            <RxCross2 onClick={handleClose5} className="c-p" />
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="">
              <input
                type="text"
                className="input b-c-t w-100 b-r-15 pa-0_5 m-b-1"
                placeholder="New discription"
                onChange={(e) => setNewDiscription(e.target.value)}
              />{" "}
              <button
                className="b-c-t p-y-0_5 p-x-1  bg_btn_clr btn_clr   b-r-5 m-y-0_5 b-n m-q-m-r-2 m-q-w-100"
                onClick={() => updateDiscription()}
              >
                Submit
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default My_lock;
