import { createStore, action } from 'easy-peasy';

const MainStore = createStore({
    user: "0xAFf2c41310590081788687a7125677f520000000",
    setUser: action((state, payload) => {
        state.user = payload;
    }),

    
    connectMetamask: undefined,
    setConnectMetamask: action((state, payload)=>{
        state.connectMetamask = payload;
    }),
    connectWalletConnect: undefined,
    setConnectWalletConnect: action((state, payload)=>{
        state.connectWalletConnect = payload;
    })
  });
  
  export default MainStore;
