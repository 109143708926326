import { Backdrop, CircularProgress, Grid, Slider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { splitSignature } from "@ethersproject/bytes";

import { Link, useParams } from "react-router-dom";
import {
  AiOutlineArrowDown,
  AiOutlineArrowLeft,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import { RotatingLines } from "react-loader-spinner";
import { Modal } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import { BsArrowUpCircle } from "react-icons/bs";
import { ERC_TOKEN } from "../../Web3/Tokens";
import { useStoreState } from "easy-peasy";

import {
  Allow,
  Approve,
  fromwie,
  get_ERC_20_Contract,
  get_Factory_Contract,
  get_PAIR_Contract,
  get_Router_Contract,
  get_Token_Balance,
  towie,
  userBalanceOfChain,
} from "../../Web3/web3";
import { ROUTERADDRESS } from "../../Web3/Contract";
import { toast } from "react-toastify";
import { useWeb3React } from "@web3-react/core";
import Footer from "./Footer";

const notify = (msg) =>
  toast.success(msg, {
    position: toast.POSITION.TOP_LEFT,
  });

function Home() {
  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);

  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);

  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [show7, setShow7] = useState(false);

  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const user = useStoreState((state) => state.user);

  const [open, setOpen] = useState(false);
  const { pair } = useParams();
  const [logoA, setLogoA] = useState();
  const [logoB, setLogoB] = useState();
  const [tokenAbalanceofpair, setTokenABalanceOfPair] = useState();
  const [tokenBbalanceofpair, setTokenBBalanceOfPair] = useState();
  const [symbolA, setSymbolA] = useState();
  const [symbolB, setSymbolB] = useState();
  const [tokenAprice, setTokenAPrice] = useState();
  const [tokenBprice, setTokenBPrice] = useState();
  const [removeableamount, setRemoveableAmount] = useState(0);
  const [routerContract, setRouterContract] = useState();
  const [token1, setToken1] = useState();
  const [token2, setToken2] = useState();
  const [isapproved, setIsApproved] = useState();
  const [deadline, setDeadline] = useState();
  const [v, setV] = useState();
  const [r, setR] = useState();
  const [s, setS] = useState();
  const { provider, account } = useWeb3React();

  useEffect(() => {
    const init = async () => {
      try {
        let userPoolDetailsArray =
          JSON.parse(localStorage.getItem("userPoolDetailsArray")) || [];
        const pairInfo = userPoolDetailsArray.findIndex(
          (item) => item.pair === pair
        );
        const tokenA = userPoolDetailsArray[pairInfo].token_A;
        const tokenB = userPoolDetailsArray[pairInfo].token_B;
        setToken1(tokenA);
        setToken2(tokenB);
        setLogoA(userPoolDetailsArray[pairInfo].token1logo);
        setLogoB(userPoolDetailsArray[pairInfo].token2logo);
        const token1Contract = await get_ERC_20_Contract(tokenA);
        const token2Contract = await get_ERC_20_Contract(tokenB);

        const balanceOfPairInToken1 = await token1Contract.methods
          .balanceOf(pair)
          .call();
        const balanceOfPairInToken2 = await token2Contract.methods
          .balanceOf(pair)
          .call();

        const tokenAsymbol = await token1Contract.methods.symbol().call();
        const tokenBsymbol = await token2Contract.methods.symbol().call();
        setSymbolA(tokenAsymbol);
        setSymbolB(tokenBsymbol);

        setTokenABalanceOfPair(await fromwie(balanceOfPairInToken1));
        setTokenBBalanceOfPair(await fromwie(balanceOfPairInToken2));

        const One = await towie(1);
        const router = await get_Router_Contract();
        setRouterContract(router);
        const tokenAPrice = await router.methods
          .quote(One, balanceOfPairInToken1, balanceOfPairInToken2)
          .call();
        const tokenBPrice = await router.methods
          .quote(One, balanceOfPairInToken2, balanceOfPairInToken1)
          .call();

        setTokenAPrice(await fromwie(tokenAPrice));
        setTokenBPrice(await fromwie(tokenBPrice));
      } catch (error) {}
    };
    init();
  }, [user]);

  const setAmountToRemove = async (percent) => {
    const pairContract = await get_PAIR_Contract(pair);
    const userPoolBalance = await pairContract.methods.balanceOf(user).call();
    const normal = await fromwie(userPoolBalance);
    const toRemove = (Number(normal) * Number(percent)) / 100;
    setRemoveableAmount(toRemove);
  };

  const CalculateMin = async (amount) => {
    const amount05 = (Number(amount) * 50) / 100;
    const minAmount = Number(amount) - amount05;
    const min = await towie(minAmount);
    return min;
  };
  const CalculateMinLiqu = async (amount) => {
    const amount05 = (Number(amount) * 55) / 100;
    const minAmount = Number(amount) - amount05;
    const min = await towie(minAmount);
    return min;
  };

  const RemoveLiquidity = async () => {
    try {
      const deadlinetime = Number(
        (new Date().getTime() + 1200000) / 1000
      ).toFixed(0);
      setOpen(true);
      if (symbolA === "WMAAL" || symbolB === "WMAAL") {
        if (symbolA === "WMAAL") {
          console.log("Frist token is maal");
          const remove = await towie(removeableamount);
          const tokenAmountmin = await CalculateMin(tokenBbalanceofpair);
          const maalAmountmin = await CalculateMin(tokenAbalanceofpair);
          console.log(
            token2,
            remove,
            tokenAmountmin,
            maalAmountmin,
            user,
            deadlinetime
          );
          const data = await routerContract.methods
            .removeLiquidityMAAL(
              token2,
              remove,
              tokenAmountmin,
              maalAmountmin,
              user,
              deadlinetime
            )
            .send({ from: user });
          if (data.status) {
            notify("Success");
            setOpen(false);
          }
        } else {
          const remove = await towie(removeableamount);
          const tokenAmountmin = await CalculateMin(tokenAbalanceofpair);
          const maalAmountmin = await CalculateMin(tokenBbalanceofpair);
          console.log(
            "second token is maal",
            token1,
            remove,
            tokenAmountmin,
            maalAmountmin,
            user,
            deadlinetime
          );
          const data = await routerContract.methods
            .removeLiquidityMAAL(
              token1,
              remove,
              tokenAmountmin,
              maalAmountmin,
              user,
              deadlinetime
              // false,
              // v,
              // r,
              // s
            )
            .send({ from: user });
          if (data.status) {
            notify("Success");
            setOpen(false);
          }
        }
      } else {
        const remove = await towie(removeableamount);
        const tokenAmountmin = await CalculateMin(tokenAbalanceofpair);
        const maalAmountmin = await CalculateMin(tokenBbalanceofpair);
        console.log(
          "Both ERC20 token",
          token1,
          token2,
          remove,
          tokenAmountmin,
          maalAmountmin,
          user,
          deadlinetime
        );
        const data = await routerContract.methods
          .removeLiquidity(
            token1,
            token2,
            remove,
            tokenAmountmin,
            maalAmountmin,
            user,
            deadlinetime
            // false,
            // v,
            // r,
            // s
          )
          .send({ from: user });
        if (data.status) {
          notify("Success");
          setOpen(false);
        }
      }
    } catch (error) {
      setOpen(false);
      console.log(error);
    }
  };

  const ApprovePair = async () => {
    // const deadlinetime = Number( (new Date().getTime() + 1200000) / 1000).toFixed(0);
    // setDeadline(deadlinetime);
    // const remove = await towie(removeableamount);
    // const data2 = {
    //   domain: {
    //     name: "RamzSwap",
    //     version: "1",
    //     verifyingContract: pair,
    //     chainId: 880,
    //   },
    //   primaryType: "Permit",
    //   message: {
    //     owner: account,
    //     spender: ROUTERADDRESS,
    //     value: remove,
    //     nonce: 0,
    //     deadline: deadlinetime,
    //   },
    //   types: {
    //     EIP712Domain: [
    //       { name: "name", type: "string" },
    //       { name: "version", type: "string" },
    //       { name: "chainId", type: "uint256" },
    //       { name: "verifyingContract", type: "address" },
    //     ],
    //     Permit: [
    //       { name: "owner", type: "address" },
    //       { name: "spender", type: "address" },
    //       { name: "value", type: "uint256" },
    //       { name: "nonce", type: "uint256" },
    //       { name: "deadline", type: "uint256" },
    //     ],
    //   },
    // };
    // provider.send('eth_signTypedData_v4', [account, JSON.stringify(data2)])
    // .then(splitSignature).then((signature)=>{
    //   console.log(signature.v, signature.r, signature.s)
    //   setV(signature.v)
    //   setR(signature.r)
    //   setS(signature.s)
    // })
    try {
      const remove = await towie(removeableamount);
      const allow = await Allow(user, pair, ROUTERADDRESS);
      if (allow <= remove) {
        setOpen(true);
        const data = await ApprovePair(ROUTERADDRESS, pair, remove);
        if (data.status) {
          notify("Approved");
          setOpen(false);
          setIsApproved(true);
        }
      } else {
        setIsApproved(true);
        notify("Approved");
        setOpen(false);
      }
    } catch (error) {
      setOpen(false);
    }
  };

  return (
    <>
      <div className="background_image">
        <div className="container-kws">
          <div className="m-t-3 m-b-4 d-b max-w-40rem m-a ">
            <div className="bg_br_clr b-r-15  pa-1_5">
              <div className="d-f j-c-s-b a-i-c m-b-2">
                <Link to="/pool">
                  {" "}
                  <div className="f-w-600 o_clr f-s-1_25">
                    <AiOutlineArrowLeft className="f_o_clr" />
                  </div>
                </Link>{" "}
                <div className="o_clr f-s-1_25">Remove Liquidity</div>
                <div className="txt_clr f-s-1_25">
                  <AiOutlineQuestionCircle className="f_gray_clr" />
                </div>
              </div>
              <div className="o_clr ">Amount: </div>
              <div className="o_clr m-b-1 f-s-3">{removeableamount}</div>
              <div className="m-b-1">
                <Slider
                  defaultValue={0}
                  aria-label="Default"
                  onChange={(e) => setAmountToRemove(e.target.value)}
                  // valueLabelDisplay="auto"
                />
              </div>
              <div className="d-f a-i-c j-c-s-b">
                <button
                  className="b-c-t p-y-0_5 w-100 bg_btn_clr btn_clr m-r-0_5 f-s-1_5 f-w-600 b-r-15 b-n"
                  onClick={() => setAmountToRemove(25)}
                >
                  25%
                </button>
                <button
                  className="b-c-t p-y-0_5 w-100 bg_btn_clr btn_clr m-r-0_5 f-s-1_5 f-w-600 b-r-15 b-n"
                  onClick={() => setAmountToRemove(50)}
                >
                  50%
                </button>
                <button
                  className="b-c-t p-y-0_5 w-100 bg_btn_clr btn_clr m-r-0_5 f-s-1_5 f-w-600 b-r-15 b-n"
                  onClick={() => setAmountToRemove(75)}
                >
                  75%
                </button>
                <button
                  className="b-c-t p-y-0_5 w-100 bg_btn_clr btn_clr f-s-1_5 f-w-600 b-r-15 b-n"
                  onClick={() => setAmountToRemove(100)}
                >
                  MAX
                </button>
              </div>
              <div className="t-a-c m-y-2 ">
                <AiOutlineArrowDown className="f-s-1_5" />
              </div>

              <div className="  ">
                <div className="d-f a-i-c j-c-s-b m-b-1 f-s-1_5 o_clr">
                  <div className="">
                    {Number(tokenAbalanceofpair).toFixed(3)}
                  </div>
                  <div className="d-f a-i-c ">
                    <div className="m-r-0_5">
                      <img src={logoA} className="w-2 h-2 b-r-50" />
                    </div>
                    <div className="t-t-u">{symbolA}</div>
                  </div>
                </div>
                <div className="d-f a-i-c j-c-s-b m-b-1 f-s-1_5 o_clr">
                  <div className="">
                    {Number(tokenBbalanceofpair).toFixed(3)}
                  </div>
                  <div className="d-f a-i-c ">
                    <div className="m-r-0_5">
                      <img src={logoB} className="w-2 h-2 b-r-50" />
                    </div>
                    <div className="t-t-u">{symbolB}</div>
                  </div>
                </div>
                <div className="d-f a-i-c j-c-s-b  f-s-1_5 m-y-1">
                  <div className="t-t-u">Price :</div>

                  <div className="">
                    1 {symbolA} = {Number(tokenBprice).toFixed(3)} {symbolB}
                    <br />1 {symbolB} = {Number(tokenAprice).toFixed(3)}{" "}
                    {symbolA}
                  </div>
                </div>
                <div className="d-f a-i-c j-c-c ">
                  <button
                    href="/Add_liquidity"
                    className="b-c-t p-y-0_5 w-100 bg_btn_clr btn_clr f-s-1_5 f-w-600 b-r-15 b-n m-r-1"
                    onClick={() => ApprovePair()}
                  >
                    Approve
                  </button>

                  <button
                    className="b-c-t p-y-0_5 w-100 bg_btn_clr btn_clr f-s-1_5 f-w-600 b-r-15 b-n"
                    onClick={handleShow6}
                    disabled={!isapproved}
                  >
                    Remove
                  </button>
                </div>
              </div>

              {/* <div className="">
                {!approve1symbol && !approve2symbol ? (
                  <button
                    className="b-c-t p-y-1 w-100 bg_btn_clr btn_clr f-s-1_5 f-w-600 b-r-15 b-n"
                    onClick={() => {
                      if (user) {
                        AddingLiqudity();
                      }
                    }}
                  >
                    {Statementshow()}
                  </button>
                ) : (
                  ""
                )}

                {user && approve1symbol ? (
                  <button
                    className="b-c-t p-y-1 w-100 bg_btn_clr btn_clr f-s-1_5 f-w-600 b-r-15 b-n"
                    onClick={() => Approvetoken(approve1token)}
                  >
                    {`Approve ${approve1symbol}`}
                  </button>
                ) : (
                  ""
                )}

                {user && approve2symbol ? (
                  <button
                    className="b-c-t p-y-1 w-100 bg_btn_clr btn_clr f-s-1_5 f-w-600 b-r-15 b-n m-t-1"
                    onClick={() => Approvetoken(approve2token)}
                  >
                    {`Approve ${approve2symbol}`}
                  </button>
                ) : (
                  ""
                )}
              </div> */}
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <Modal show={show3} onHide={handleClose3} className="b-n  o-n ">
        <Modal.Body className="b-n o-n bg_br_clr o_clr b-r-15">
          <div className="p-y-1">
            <div className="d-f a-i-c j-c-s-b m-b-1 o_clr  b-r-15">
              <div className="f-s-1_25">You are creating a pool</div>
              <div className="">
                {" "}
                <RxCross2
                  className="f-s-1_5 c-p f_o_clr o_clr"
                  onClick={handleClose3}
                />{" "}
              </div>
            </div>
            <div className="f-s-2_5 f-w-600 p-y-2 t-a-c">CHOOKY / ART</div>
            <div className="">
              <div className="d-f a-i-c j-c-s-b f-s-1_25 m-y-1">
                <div className="">CHOOKY Deposited</div>
                <div className="">
                  <AiOutlineQuestionCircle /> &nbsp; 9000
                </div>
              </div>
              <div className="d-f a-i-c j-c-s-b f-s-1_25 m-y-1">
                <div className="">ART Deposited</div>
                <div className="">
                  <AiOutlineQuestionCircle /> &nbsp; 800016000
                </div>
              </div>
              <div className="d-f a-i-c j-c-s-b f-s-1_25 m-y-1">
                <div className="">Rates</div>
                <div className="t-a-r">
                  {" "}
                  &nbsp; 1 CHOOKY = 8889 ART <br /> 1 ART = 0.0001125 CHOOKY
                </div>
              </div>
              <div className="d-f a-i-c j-c-s-b f-s-1_25 m-y-1">
                <div className="">Share of Pool :</div>
                <div className="">100%</div>
              </div>
              <div className="">
                <button className="b-c-t p-y-1 w-100 bg_o_clr  f-s-1_5 f-w-600 max_clr b-r-15 b-n">
                  Create Pool & Supply
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show4} onHide={handleClose4} className="b-n  o-n ">
        <Modal.Body className="b-n o-n bg_br_clr o_clr b-r-15">
          <div className="p-y-1">
            <div className="d-f a-i-c j-c-s-b m-b-1 o_clr  b-r-15">
              <div className="f-s-1_25"></div>
              <div className="">
                {" "}
                <RxCross2
                  className="f-s-1_5 c-p f_o_clr o_clr"
                  onClick={handleClose4}
                />{" "}
              </div>
            </div>
            <div className="f-s-2_5  p-y-2 t-a-c">
              <BsArrowUpCircle className="f-s-7 f_o_clr" />
            </div>
            <div className="f-s-1_5  t-a-c m-y-2">Transaction Submitted</div>
            <div className="  t-a-c m-y-2">View on Bscscan</div>
            <div className="">
              <button className="b-c-t p-y-1 w-100 bg_o_clr  f-s-1_5 f-w-600 max_clr b-r-15 b-n">
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show5} onHide={handleClose5} className="b-n  o-n ">
        <Modal.Body className="b-n o-n bg_br_clr o_clr b-r-15">
          <div className="p-y-1">
            <div className="f-s-2_5  p-y-2 t-a-c">
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
              />
            </div>
            <div className="f-s-1_5  t-a-c m-y-2">Waiting For Confirmation</div>
            <div className="  t-a-c m-y-2">
              Supplying 0.001 BNB and 800016000 ART
            </div>
            <div className=" gray_clr t-a-c m-y-2 f-s-0_8">
              Confirm this transaction in your wallet
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show6} onHide={handleClose6} className="b-n  o-n ">
        <Modal.Body className="b-n o-n bg_br_clr b-r-15">
          <div className="p-y-1">
            <div className="d-f a-i-c j-c-s-b m-b-1 o_clr  b-r-15">
              <div className="f-s-1_5">You will receive</div>
              <div className="">
                {" "}
                <RxCross2
                  className="f-s-1_5 c-p f_o_clr o_clr"
                  onClick={handleClose6}
                />{" "}
              </div>
            </div>
            <div className="d-f a-i-c j-c-s-b m-t-1 m-b-0_5 f-s-1_5 o_clr">
              <div className="">{tokenAbalanceofpair}</div>
              <div className="d-f a-i-c ">
                <div className="m-r-0_5">
                  <img src={logoA} className="w-2 h-2 b-r-50" />
                </div>
                <div className="t-t-u">{symbolA}</div>
              </div>
            </div>
            <div className="t-a-c f-s-1_25">+</div>
            <div className="d-f a-i-c j-c-s-b m-t-0_5 m-b-1 f-s-1_5 o_clr">
              <div className="">{tokenBbalanceofpair}</div>
              <div className="d-f a-i-c ">
                <div className="m-r-0_5">
                  <img src={logoB} className="w-2 h-2 b-r-50" />
                </div>
                <div className="t-t-u">{symbolB}</div>
              </div>
            </div>
            <div className="m-y-2">
              Output is estimated. if the price changes by more than 0.5% your
              transaction will revert.
            </div>
            {/* <div className="d-f a-i-c j-c-s-b m-t-0_5 m-y-2 f-s-1_5 o_clr">
              <div className="">UNI BNB/CHOOKY BURNED</div>
              <div className="d-f a-i-c ">
                <div className="m-r-0_5">
                  <img src="" className="w-2 h-2 b-r-50" />
                </div>
                <div className="m-r-0_5">
                  <img src="" className="w-2 h-2 b-r-50" />
                </div>
                <div className="t-t-u">9.74679</div>
              </div>
            </div> */}
            {/* <div className="d-f a-i-c j-c-s-b m-y-2 f-s-1_5 m-y-1">
              <div className="t-t-u">Price :</div>

              <div className="">
                1BNB = 3 CHOOKY
                <br />
                1CHOOKY = 3BNB
              </div>
            </div> */}
            <div className="d-f a-i-c j-c-c ">
              <button
                href="/Add_liquidity"
                className="b-c-t p-y-0_5 w-100 bg_btn_clr btn_clr f-s-1_5 f-w-600 b-r-15 b-n "
                onClick={() => RemoveLiquidity()}
              >
                CONFIRM
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show7} onHide={handleClose7} className="b-n  o-n ">
        <Modal.Body className="b-n o-n bg_br_clr b-r-15">
          <div className="p-y-1">
            <div className="d-f a-i-c j-c-s-b m-b-1 o_clr  b-r-15">
              <div className="f-s-1_25">
                Select a token{" "}
                <AiOutlineQuestionCircle className="f-s-1_5 m-l-0_5  f_gray_clr" />
              </div>
              <div className="">
                {" "}
                <RxCross2
                  className="f-s-1_5 c-p f_o_clr o_clr"
                  onClick={handleClose7}
                />{" "}
              </div>
            </div>
            <div className="m-b-2">
              <input
                type="text"
                className="input search b-1 pa-0_5 b-c-t b-r-10 w-100 o_clr f-s-1_25 "
                placeholder="Search name or paste address"
              />
            </div>
            <div className="o_clr m-b-1">Token Name</div>
          </div>
        </Modal.Body>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Home;
