import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import Dropdown from "react-bootstrap/Dropdown";
import { MdKeyboardArrowDown } from "react-icons/md";
import bnb from "../Image/bnb.png";
import eth from "../Image/eth.png";
import { BiDownArrowAlt } from "react-icons/bi";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { get_ERC_20_Contract, get_Factory_Contract } from "../../Web3/web3";
import { useStoreState } from "easy-peasy";
import Footer from "./Footer";

function Home() {
  const [visible, setvisibles] = useState();
  const user = useStoreState((state) => state.user);
  const [importedLiquidity, setImportedLiquidity] = useState([]);

  useEffect(() => {
    const init = async () => {
      let userPoolDetailsArray =
        JSON.parse(localStorage.getItem("userPoolDetailsArray")) || [];
      // console.log("pool details", userPoolDetailsArray);

      const pairs = [];
      for (let i = 0; i < userPoolDetailsArray.length; i++) {
        const tokenAcontract = await get_ERC_20_Contract(
          userPoolDetailsArray[i].token_A
        );
        const tokenBcontract = await get_ERC_20_Contract(
          userPoolDetailsArray[i].token_B
        );
        const pairContract = await get_ERC_20_Contract(
          userPoolDetailsArray[i].pair
        );

        const pairBalanceIntokenA = await tokenAcontract.methods
          .balanceOf(userPoolDetailsArray[i].pair)
          .call();
        const pairBalanceIntokenB = await tokenBcontract.methods
          .balanceOf(userPoolDetailsArray[i].pair)
          .call();

        const nametokenA = await tokenAcontract.methods.symbol().call();
        const nametokenB = await tokenBcontract.methods.symbol().call();

        const userPairBalance = await pairContract.methods
          .balanceOf(user)
          .call();

        userPoolDetailsArray[i].pairBalanceIntokenA = pairBalanceIntokenA;
        userPoolDetailsArray[i].pairBalanceIntokenB = pairBalanceIntokenB;
        userPoolDetailsArray[i].nametokenA = nametokenA;
        userPoolDetailsArray[i].nametokenB = nametokenB;
        userPoolDetailsArray[i].userPairBalance = userPairBalance;
        pairs.push(userPoolDetailsArray[i]);
      }
      setImportedLiquidity(pairs);
    };
    init();
  }, [user]);

  // console.log(importedLiquidity)

  const RemoveUserPoolDetails = (pair) => {
    // Retrieve the existing array from local storage
    let userPoolDetailsArray =
      JSON.parse(localStorage.getItem("userPoolDetailsArray")) || [];

    const indexToRemove = userPoolDetailsArray.findIndex(
      (item) => item.pair === pair
    );

    if (indexToRemove !== -1) {
      userPoolDetailsArray.splice(indexToRemove, 1);
    }

    localStorage.setItem(
      "userPoolDetailsArray",
      JSON.stringify(userPoolDetailsArray)
    );
  };

  return (
    <>
      <div className="background_image t-a-c">
        <div className="container-kws">
          <div className="">
            <div className="m-t-3 m-a max-w-40rem">
              <div className="bg_br_clr  b-r-15 pa-1_5">
                <div className="d-f j-c-s-e a-i-c  m-b-3">
                  <Link to="/">
                    {" "}
                    <div className="txt_clr f-s-1_25">Swap</div>
                  </Link>
                  <Link to="/pool">
                    {" "}
                    <div className="f-w-600 o_clr f-s-1_25">Pool</div>
                  </Link>
                </div>

                <Link to="/Add_liquidity">
                  {" "}
                  <div className="">
                    <button className="b-c-t p-y-1 w-100 bg_btn_clr btn_clr f-s-1_5 f-w-600 b-r-15 b-n">
                      Add Liquidity
                    </button>
                  </div>{" "}
                </Link>
                <div className="m-t-1 d-f j-c-s-b a-i-c m-b-2">
                  {" "}
                  <div className="o_clr">Your Liquidity</div>{" "}
                  <div className="">
                    <AiOutlineQuestionCircle className="f_gray_clr" />
                  </div>
                </div>
                {/*     <div className="t-a-c m-b-2 gray_clr p-y-3">
                  No liquidity found.
                </div> */}

                {importedLiquidity &&
                  importedLiquidity.map((res) => {
                    return (
                      <div className="bg_box_clr  b-r-15 m-b-1">
                        <div
                          className="d-f a-i-c j-c-s-b  pa-1 c-p o_clr f-w-600 w-s-n-w"
                          onClick={() =>
                            setvisibles(
                              visible == importedLiquidity.indexOf(res)
                                ? undefined
                                : importedLiquidity.indexOf(res)
                            )
                          }
                        >
                          <div className="d-f a-i-c">
                            <img
                              src={res.token1logo}
                              className="w-1_5 h-1_5 b-r-50 m-r-0_5"
                            />
                            <img
                              src={res.token2logo}
                              className="w-1_5 h-1_5 b-r-50 m-r-0_5"
                            />
                            <div className="">
                              <span className="m-r-1">
                                {res.nametokenA}/{res.nametokenB}
                              </span>
                            </div>
                          </div>
                          <div className=" ">
                            <div className="">
                              <MdKeyboardArrowDown className="f_o_clr b-c-t  f-s-1_5" />
                            </div>
                          </div>
                        </div>
                        {visible == importedLiquidity.indexOf(res) ? (
                          <div className="pa-1">
                            <div className="d-f  a-i-c j-c-s-b m-y-1">
                              <div className="">Pooled {res.nametokenA} :</div>
                              <div className="d-f a-i-c">
                                <div className="m-r-0_5">
                                  {Number(
                                    Number(res.pairBalanceIntokenA) /
                                      10 ** res.decimalTokenA
                                  ).toFixed(3)}
                                </div>
                                <div className="">
                                  <img
                                    src={res.token1logo}
                                    className="w-1_5 h-1_5 b-r-50 "
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="d-f a-i-c j-c-s-b m-y-1">
                              <div className="">Pooled {res.nametokenB} :</div>
                              <div className="d-f a-i-c">
                                <div className="m-r-0_5">
                                  {Number(
                                    Number(res.pairBalanceIntokenB) /
                                      10 ** res.decimalTokenB
                                  ).toFixed(3)}
                                </div>
                                <div className="">
                                  <img
                                    src={res.token2logo}
                                    className="w-1_5 h-1_5 b-r-50 "
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="d-f a-i-c j-c-s-b m-y-1">
                              <div className="">Your pool tokens :</div>
                              <div className="">
                                {Number(
                                  Number(res.userPairBalance) / 10 ** 18
                                ).toFixed(3)}
                              </div>
                            </div>
                            {/* <div className="d-f a-i-c j-c-s-b m-y-1">
                      <div className="">Your pool share :</div>
                      <div className="">100.00%</div>
                    </div> */}
                            <div className="d-f a-i-c j-c-c ">
                              <button className="b-c-t p-y-0_5 w-100 bg_btn_clr btn_clr f-s-1_5 f-w-600 b-r-15 b-n m-r-1">
                                {" "}
                                <a href="/Add_liquidity">
                                  <div>Add </div>
                                </a>
                              </button>

                              <button className="b-c-t p-y-0_5 w-100 bg_btn_clr btn_clr f-s-1_5 f-w-600 b-r-15 b-n">
                                <Link to={`/RemoveLiquidity/${res.pair}`}>
                                  {" "}
                                  <div> Remove</div>{" "}
                                </Link>
                              </button>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}

                <div className="t-a-c o_clr">
                  Don't see a pool you joined?{" "}
                  <Link to="/earning">
                    {" "}
                    <span className="txt_clr">Import it.</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Home;
