import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import { AiFillLock } from "react-icons/ai";
import {
  convertToLowercase,
  get_ERC_20_Contract,
  get_Locker_Contract,
  get_PAIR_Contract,
} from "../../Web3/web3";
import { ERC_TOKEN } from "../../Web3/Tokens";
import { useStoreState } from "easy-peasy";
import isEthereumAddress from "validator/lib/isEthereumAddress";
import All_lock_tab from "./All_lock_tab";
import Footer from "./Footer";

function My_lock() {
  const user = useStoreState((state) => state.user);

  const [alltokenLocked, setAllTokenLocked] = useState();
  const [tokentokenLocked, setTotalTokenLocked] = useState();
  const [mappingLP, setMappingLP] = useState([]);

  useEffect(() => {
    const init = async () => {
      const lpContract = await get_Locker_Contract();
      const alltokenCount = await lpContract.methods
        .allLpTokenLockedCount()
        .call();
      setTotalTokenLocked(alltokenCount);
      const tokenList = [];
      let lpDetails = {};
      for (let i = 0; i < parseInt(alltokenCount); i++) {
        const tokens = await lpContract.methods
          .getCumulativeLpTokenLockInfoAt(i)
          .call();
        const pairContract = await get_PAIR_Contract(tokens[0]);
        const token0 = await pairContract.methods.token0().call();
        const token1 = await pairContract.methods.token1().call();
        const token0Index = ERC_TOKEN.findIndex(
          (item) => item.address === token0
        );
        const token1Index = ERC_TOKEN.findIndex(
          (item) => item.address === token1
        );
        if (token0Index < 0 && token1Index > 0) {
          const token2Details = ERC_TOKEN[token1Index];
          const tokenContract = await get_ERC_20_Contract(token0);
          const name = await tokenContract.methods.name().call();
          const symbol = await tokenContract.methods.symbol().call();
          lpDetails.name = name;
          lpDetails.symbol = symbol;
          lpDetails.name2 = token2Details.name;
          lpDetails.address2 = token2Details.address;
          lpDetails.logo2 = token2Details.logo;
          lpDetails.symbol2 = token2Details.symbol;
          lpDetails.pair = tokens[0];
          tokenList.push(lpDetails);
        }
        if (token0Index > 0 && token1Index < 0) {
          const token1Details = ERC_TOKEN[token0Index];
          lpDetails = {
            ...token1Details,
          };
          const tokenContract = await get_ERC_20_Contract(token1);
          const name2 = await tokenContract.methods.name().call();
          const symbol2 = await tokenContract.methods.symbol().call();
          lpDetails.name2 = name2;
          lpDetails.symbol2 = symbol2;
          lpDetails.pair = tokens[0];
          tokenList.push(lpDetails);
        } else {
          const token1Details = ERC_TOKEN[token0Index];
          const token2Details = ERC_TOKEN[token1Index];
          lpDetails = {
            ...token1Details,
          };
          lpDetails.name2 = token2Details.name;
          lpDetails.address2 = token2Details.address;
          lpDetails.logo2 = token2Details.logo;
          lpDetails.symbol2 = token2Details.symbol;
          lpDetails.pair = tokens[0];
          tokenList.push(lpDetails);
        }
      }
      setAllTokenLocked(tokenList);
      setMappingLP(tokenList);
    };
    init();
  }, []);

  const sliceString = (str) => {
    return str?.length > 25 ? `${str.substring(0, 6)}...${str.substr(36)}` : ``;
  };

  const search = async (address) => {
    try {
      if (address == "") {
        setAllTokenLocked(mappingLP);
        return;
      }
      const valid = isEthereumAddress(
        address === "0x0000000000000000000000000000000000000000" ? "" : address
      );
      if (valid) {
        const result = alltokenLocked.filter(function (element) {
          return (
            convertToLowercase(element.address) ==
              convertToLowercase(address) ||
            convertToLowercase(element.address2) ==
              convertToLowercase(address) ||
            convertToLowercase(element.pair) == convertToLowercase(address)
          );
        });
        setAllTokenLocked(result);
      } else {
        // notify("Invalid address")
        setAllTokenLocked([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className=" background_image ">
        <div className="container-kws">
          <div className="bg_br_clr  b-r-15 m-t-4 m-b-5  pa-1_5">
            <All_lock_tab />

            <div className="m-q-d-f a-i-c  m-t-2 ">
              <div className="bg_o_clr max_clr w-s-n-w p-x-2  p-y-0_5 b-r-15 m-q-m-r-2 m-y-1 t-t-u t-a-c">
                Lock or manage liquidity
              </div>
              <div className="w-100 m-q-m-r-2 m-y-1 ">
                <input
                  type="text"
                  className="input w-100 b-r-15  pa-0_5 b-c-t"
                  onChange={(e) => search(e.target.value)}
                  placeholder="Search by token address..."
                />
              </div>

              <div className="t-a-c m-y-1 ">
                {" "}
                <span className="w-s-n-w">
                  <AiFillLock /> Total Liquidity Lockers :
                </span>{" "}
                <span className="f-w-600">{tokentokenLocked}</span>{" "}
              </div>
            </div>

            <div className="row m-t-4">
              {alltokenLocked &&
                alltokenLocked.map((res) => {
                  return (
                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 m-y-1">
                      <div className="pa-2 b-r-15 bg_btn_clr o_clr t-a-c">
                        <div className="f-s-1_25 f-w-600 m-b-1">
                          {res.symbol}/{res.symbol2}
                        </div>
                        {/* <div className="m-b-2">LOVELY/WBNB</div> */}
                        {/* <div className="">Unlocked on</div>
                <div className="m-b-1">4 Aug 2023 at 17:58</div> */}
                        <hr />
                        <div className="">Token Address : </div>
                        <div className="">{sliceString(res.pair)}</div>
                      </div>
                    </div>
                  );
                })}
              {alltokenLocked && alltokenLocked.length > 0 ? (
                ""
              ) : (
                <div className="p-b-5 p-t-2 t-a-c ">No data to show...</div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default My_lock;
