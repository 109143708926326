import React, { useState } from "react";
import cyber from "../Image/cyber.png";
import { AiOutlineCopy } from "react-icons/ai";
import {
  Allow,
  Approve,
  fromwie,
  get_Locker_Contract,
  get_PAIR_Contract,
  towie,
} from "../../Web3/web3";
import { DatePicker, Space } from "antd";
import { useStoreState } from "easy-peasy";
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from "@mui/material";
import { LOCKER_ADDRESS } from "../../Web3/Contract";
import Footer from "./Footer";

const notify = (msg) =>
  toast.success(msg, {
    position: toast.POSITION.TOP_LEFT,
  });

function Create_lock() {
  const [open, setOpen] = useState();
  const [lock_time, setlock_times] = useState(false);
  const user = useStoreState((state) => state.user);
  const [tokenAddress, setTokanAddress] = useState();
  const [title, setTitle] = useState();
  const [amount, setAmount] = useState();
  const [lockTime, setLockTime] = useState();
  const [isLpToken, setIsLpToken] = useState(false);
  const [tokenBalance, setTokenBalance] = useState();
  const [tgapercent, setTGAPercent] = useState();
  const [cycleDays, setCycleDays] = useState();
  const [cyclepercent, setCyclePercent] = useState();
  const [isApprove, setIsApprove] = useState(false);
  const [symbol, setSymbol] = useState();

  const setTimeLock = (value) => {
    const timemiliseconds = new Date(value).getTime();
    const timeInsecons = Number(timemiliseconds / 1000).toFixed(0);
    setLockTime(timeInsecons);
  };

  const setLockTokenAddress = async (address) => {
    try {
      isAllow(address);
      const lpContract = await get_PAIR_Contract(address);
      const balance = await lpContract.methods.balanceOf(user).call();
      const normalBlanace = await fromwie(balance);
      setTokenBalance(normalBlanace);
      setTokanAddress(address);

      const sym = await lpContract.methods.symbol().call();
      setSymbol(sym);
      const reserver = await lpContract.methods.token0().call();
      if (reserver) {
        setIsLpToken(true);
      } else {
        setIsLpToken(false);
      }
    } catch (error) {
      console.log(error);
      setIsLpToken(false);
    }
  };

  const onOk = (value) => {
    console.log("onOk: ", value);
  };

  const isAllow = async (tokenAaddress) => {
    try {
      const isAllowed = await Allow(user, tokenAaddress, LOCKER_ADDRESS);
      console.log("is Approve", isAllowed);
      if (Number(isAllowed) <= 0) {
        setIsApprove(false);
      } else {
        setIsApprove(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const LockTokens = async () => {
    try {
      setOpen(true);
      const lpContract = await get_Locker_Contract();
      const a = await towie(amount);
      if (lock_time) {
        const data = await lpContract.methods
          .vestingLock(
            user,
            tokenAddress,
            isLpToken,
            a,
            lockTime,
            Number(tgapercent) * 100,
            cycleDays,
            Number(cyclepercent) * 100,
            title
          )
          .send({ from: user });
        if (data.status) {
          notify("Token Locked");
          setOpen(false);
        }
      } else {
        const data = await lpContract.methods
          .lock(user, tokenAddress, isLpToken, a, lockTime, title)
          .send({ from: user });
        if (data.status) {
          notify("Token Locked");
          setOpen(false);
        }
      }
    } catch (error) {
      setOpen(false);
      console.log(error);
    }
  };

  const Approvetoken = async (token) => {
    try {
      setOpen(true);

      const data = await Approve(LOCKER_ADDRESS, token);
      if (data.status) {
        notify("Approved");
        setOpen(false);
        isAllow(tokenAddress);
      }
    } catch (error) {
      setOpen(false);
    }
  };

  const Statement = () => {
    if (user) {
      if (tokenAddress) {
        if (isApprove) {
          return "Lock";
        } else {
          return `Approve ${symbol}`;
        }
      } else {
        return "Enter";
      }
    } else {
      return "Connect Wallet";
    }
  };

  return (
    <div>
      <div className=" background_image ">
        <div className="container-kws max-w-50">
          <div className="">
            <div className="bg_br_clr m-t-4 m-b-5  b-r-15  pa-1_5">
              <div className="f-s-1_5 ">Create your lock</div>
              <hr />
              {/* <div className="bg_btn_clr pa-1 b-r-15 m-b-2">
                <div className="m-b-1">PinkLock is audited by:</div>
                <div className="">
                  <img src={cyber} alt="" className=" w-13 b-r-15" />
                </div>
              </div> */}
              <div className="d-f a-i-c j-c-s-b m-b-1 m-t-2">
                <div className="">Token or LP Token address*</div>
                <div>
                  {tokenBalance} {symbol}
                </div>
              </div>
              <div className="m-b-2">
                <input
                  type="text"
                  onChange={(e) => setLockTokenAddress(e.target.value)}
                  className="b-c-t w-100 b-r-15 pa-0_5 "
                />{" "}
              </div>
              <div className="m-b-1">Title</div>
              <div className="m-b-2">
                <input
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                  className="b-c-t w-100 b-r-15 pa-0_5 "
                />{" "}
              </div>
              <div className="m-b-1">Amount*</div>
              <div className="m-b-1">
                <input
                  type="text"
                  onChange={(e) => setAmount(e.target.value)}
                  className="b-c-t w-100 b-r-15 pa-0_5 "
                />{" "}
              </div>
              <div className="d-f a-i-c m-b-1">
                <input
                  type="checkbox"
                  className="b-c-t  w-1 m-b-0 m-r-1"
                  onClick={() => setlock_times(!lock_time)}
                />

                <div className="">use vesting?</div>
              </div>
              {!lock_time ? (
                <div>
                  {" "}
                  <div className="m-b-1">Lock until (UTC time)*</div>
                  <div className="m-b-2">
                    <DatePicker
                      showTime
                      onOk={setTimeLock}
                      className="b-c-t  w-100 b-r-15  pa-0_5 datepicker"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              {lock_time ? (
                <div className="row">
                  <div className="col-12 col-sm-6">
                    {" "}
                    <div className="m-b-1">TGE Date (UTC time)*</div>
                    <div className="m-b-2">
                      <DatePicker
                        showTime
                        onOk={setTimeLock}
                        className="b-c-t  w-100 b-r-15  pa-0_5 datepicker"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    {" "}
                    <div className="m-b-1">TGE Percent*</div>
                    <div className="m-b-2">
                      <input
                        onChange={(e) => setTGAPercent(e.target.value)}
                        type="text"
                        className="b-c-t w-100 b-r-15 pa-0_5 "
                      />{" "}
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    {" "}
                    <div className="m-b-1">Cycle (days)*</div>
                    <div className="m-b-2">
                      <input
                        type="text"
                        onChange={(e) => setCycleDays(e.target.value)}
                        className="b-c-t w-100 b-r-15 pa-0_5 "
                      />{" "}
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    {" "}
                    <div className="m-b-1">Cycle Release Percent*</div>
                    <div className="m-b-2">
                      <input
                        onChange={(e) => setCyclePercent(e.target.value)}
                        type="text"
                        className="b-c-t w-100 b-r-15 pa-0_5 "
                      />{" "}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="bg_btn_clr pa-1 b-r-15 m-b-2">
                <div className="">
                  Please exclude RamzLock's lockup address
                  0xbf0eeC8A546025C5037eB8dc12275A869Cb618e9 <AiOutlineCopy />{" "}
                  from fees, rewards, max tx amount to start locking tokens. We
                  don't support rebase tokens.
                </div>
              </div>
              <div className="m-b-1 t-a-c">
                <button
                  className="b-c-t b-n bg_o_clr max_clr p-x-2 p-y-0_5 b-r-15"
                  onClick={() => {
                    if (user) {
                      if (isApprove) {
                        LockTokens();
                      } else {
                        Approvetoken(tokenAddress);
                      }
                    }
                  }}
                >
                  {Statement()}
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default Create_lock;
