export const ERC_TOKEN = [{
    address:"0xafd75251c0f1005944C7BcA06975F46aCB2EB9F7",
    name:"Wrapped MAAL",
    symbol:"MAAL",
    logo:"https://bafkreidwbojuklfg6ey5ofzeguluvwe554av3m7qhlom5azbs5is6yraoa.ipfs.nftstorage.link",
    decimal:18
},{
    address:"0xc1F2cc7175FE862F670F0Baf49b1112dd3D3f485",
    name:"TEST CBDC",
    symbol:"tCBDC",
    logo:"https://cryptologos.cc/logos/tether-usdt-logo.png?v=026",
    decimal:18
},{
    address:"0x46e07Ac06A0E31f470a3a88a2c6093504d06f2fF",
    name:"Ringit",
    symbol:"RM",
    logo:"https://cdn-icons-png.flaticon.com/512/38/38282.png",
    decimal:18
},{
    address:"0x07C30783FD8952f2037408406B09166b3244eea9",
    name:"Rupees",
    symbol:"INR",
    logo:"https://ipfs.io/ipfs/QmeuNEKVGAZihV8Qp3aJhhdiTMoXbDCV76gXgmSEvS7Xei?filename=inr.png",
    decimal:18
},{
    address:"0xE7D3FC26868592177eA19696A8ee8a5838490F02",
    name:"mMATIC",
    symbol:"mMATIC",
    logo:"https://cloudfront-us-east-1.images.arcpublishing.com/coindesk/DPYBKVZG55EWFHIK2TVT3HTH7Y.png",
    decimal:18
}
,{
    address:"0x5e955D6cc6ac6338c8F202c2b3e9B2e5d9544C78",
    name:"mMATIC",
    symbol:"mMATIC",
    logo:"https://ih1.redbubble.net/image.2396082780.4998/st,small,507x507-pad,600x600,f8f8f8.jpg",
    decimal:18
},
{
    address:"0x3f1E694cd4629a0552AAAe1d67EbEe673ee5606d",
    name:"FEE",
    symbol:"FEE",
    logo:"https://www.clipartmax.com/png/full/218-2184130_singapore-dollar-free-icon-singapore-dollar-symbol-png.png",
    decimal:18
}]