import React from "react";
import { NavLink } from "react-router-dom";

function All_lock_tab() {
  return (
    <div>
      <div className="d-f a-i-c m-t-1 flex-wrap j-c-c">
        <div className="   f-s-1_25 m-r-3  m-b-2 ">
          {" "}
          <NavLink className="all_lock" to="/my-token-lock ">
            Token
          </NavLink>{" "}
        </div>
        <div className="  f-s-1_25 m-r-3  m-b-2">
          {" "}
          <NavLink className="all_lock" to="/my-liquidity-lock">
            Liquidity
          </NavLink>{" "}
        </div>
        <div className="   f-s-1_25  m-b-2 ">
          {" "}
          <NavLink className="all_lock" to="/my-lock-detail">
            Maal ( Vested )
          </NavLink>{" "}
        </div>
      </div>
    </div>
  );
}

export default All_lock_tab;
