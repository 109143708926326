import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { StoreProvider } from "easy-peasy";
import MainStore from "./Store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Web3ReactProvider } from "@web3-react/core";
import { hooks as metaMaskHooks, metaMask } from './Web3/Connector/Metamask'
import { hooks as walletConnectV2Hooks, walletConnectV2 } from './Web3/Connector/WalletConnect'

const connectors = [
  [metaMask, metaMaskHooks],
  [walletConnectV2, walletConnectV2Hooks],
];
// import { ethers } from "ethers";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Web3ReactProvider connectors={connectors}>
    <StoreProvider store={MainStore}>
      <React.StrictMode>
        <App />
        <ToastContainer  limit={1}/>
      </React.StrictMode>
    </StoreProvider>
  </Web3ReactProvider>
);
