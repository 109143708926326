import { Backdrop, CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import Dropdown from "react-bootstrap/Dropdown";
import { MdKeyboardArrowDown } from "react-icons/md";
import bnb from "../Image/bnb.png";
import eth from "../Image/eth.png";
import meta from "../Image/meta.png";
import { BiDownArrowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft, AiOutlineQuestionCircle } from "react-icons/ai";
import { RotatingLines } from "react-loader-spinner";
import { Modal, Offcanvas } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import { BsArrowUpCircle } from "react-icons/bs";
import Wallet from "../Svg/walletconnect.svg";
import { ERC_TOKEN } from "../../Web3/Tokens";
import { useStoreState } from "easy-peasy";
import {
  Allow,
  Approve,
  convertToLowercase,
  fromwie,
  get_ERC_20_Contract,
  get_Factory_Contract,
  get_Router_Contract,
  get_Token_Balance,
  towie,
  userBalanceOfChain,
} from "../../Web3/web3";
import { ROUTERADDRESS } from "../../Web3/Contract";
import { toast } from "react-toastify";
import isEthereumAddress from "validator/lib/isEthereumAddress";
import Footer from "./Footer";

const notify = (msg) =>
  toast.success(msg, {
    position: toast.POSITION.TOP_LEFT,
  });

function Add_liquidity({ Metamask, WalletC, Dissconnect }) {
  const unknownLogo =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBGlCuL36yQjeig1VSwhDw-QsIf0mQzS97nw&usqp=CAU";
  const [erc20Tokens, setERC20Tokens] = useState(ERC_TOKEN);
  const [select1, setselect1s] = useState(false);
  const [select2, setselect2s] = useState(false);

  const [show_wallet, setShow_wallet] = useState(false);
  const handleClose_wallet = () => setShow_wallet(false);
  const handleShow_wallet = () => setShow_wallet(true);

  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);

  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);

  const handleClose6 = () => {
    setShow6(false);
    setERC20Tokens(ERC_TOKEN);
  };
  const handleShow6 = () => setShow6(true);

  const [show7, setShow7] = useState(false);

  const handleClose7 = () => {
    setShow7(false);
    setERC20Tokens(ERC_TOKEN);
  };
  const handleShow7 = () => setShow7(true);

  const user = useStoreState((state) => state.user);
  const [token1, setToken1] = useState();
  const [token2, setToken2] = useState();
  const [token1Symbol, setToken1Symbol] = useState();
  const [token2Symbol, setToken2Symbol] = useState();
  const [token1Name, setToken1Name] = useState();
  const [token2Name, setToken2Name] = useState();
  const [open, setOpen] = useState(false);
  const [token1Balance, setToken1Balance] = useState();
  const [token1logo, setToken1Logo] = useState();
  const [token2logo, setToken2Logo] = useState();
  const [token2Balance, setToken2Balance] = useState();
  const [routerContract, setRouterContract] = useState();
  const [token1amount, setToken1Amount] = useState();
  const [token2amount, setToken2Amount] = useState();
  const [isTokenValuesSwaped, setIstokenValuedSwap] = useState(false);
  const [approve1symbol, setApprove1Symbol] = useState();
  const [approve2symbol, setApprove2Symbol] = useState();
  const [approve1token, setApprove1Token] = useState();
  const [approve2token, setApprove2Token] = useState();
  const [token1decimal, setToken1Decemal] = useState(0);
  const [token2decimal, setToken2Decemal] = useState(0);
  const [token1liq, setToken1Liq] = useState();
  const [token2liq, setToken2Liq] = useState();
  const [amountout, setAmountOut] = useState();
  const [amountin, setAmountIn] = useState();
  const [invalidPair, setIsInvalidPair] = useState(false);
  const [factoryContract, setFactoryContract] = useState();
  const [haveliquidity, setHaveLiquidity] = useState(true);
  const [priceImpact, setPriceImpact] = useState();

  useEffect(() => {
    const init = async () => {
      const Native = ERC_TOKEN[0];
      setToken1(Native.address);
      setToken1Symbol(Native.symbol);
      setToken1Name(Native.name);
      setToken1Logo(Native.logo);
      setToken1Decemal(Native.decimal);
      setselect1s(true);
      const nativeBalance = await userBalanceOfChain(user);
      setToken1Balance(nativeBalance);
      const router = await get_Router_Contract();
      setRouterContract(router);
    };
    init();
  }, [user]);

  useEffect(() => {
    isAllow(token1Symbol, token2Symbol, token1, token2);

    const init = async () => {
      try {
        const fac = await get_Factory_Contract();
        setFactoryContract(fac);
        const pair = await fac.methods.getPair(token1, token2).call();

        if (pair === "0x0000000000000000000000000000000000000000") {
          setIsInvalidPair(true);
          return;
        } else {
          setIsInvalidPair(false);
        }

        const token1Contract = await get_ERC_20_Contract(token1);
        const token2Contract = await get_ERC_20_Contract(token2);

        const balanceOfPairInToken1 = await token1Contract.methods
          .balanceOf(pair)
          .call();
        const balanceOfPairInToken2 = await token2Contract.methods
          .balanceOf(pair)
          .call();

        const balanceOfPairInToken1InNumber =
          Number(balanceOfPairInToken1) / 10 ** token1decimal;
        const balanceOfPairInToken2InNumber =
          Number(balanceOfPairInToken2) / 10 ** token2decimal;

        if (
          balanceOfPairInToken1InNumber == 0 &&
          balanceOfPairInToken2InNumber == 0
        ) {
          setHaveLiquidity(false);
          return;
        }
        console.log(
          "token a and b liq",
          balanceOfPairInToken1,
          balanceOfPairInToken2
        );

        setToken1Liq(balanceOfPairInToken1InNumber);
        setToken2Liq(balanceOfPairInToken2InNumber);
      } catch (error) {}
    };
    init();
  }, [token1, token2, user, open, token1amount]);

  const isAllow = async (tokenA, tokenB, tokenAaddress, tokenBaddress) => {
    try {
      if (tokenA != "MAAL") {
        const isAllowed = await Allow(user, tokenAaddress, ROUTERADDRESS);
        if (Number(isAllowed) <= 0) {
          setApprove1Symbol(tokenA);
          setApprove1Token(tokenAaddress);
        }
      }
      if (tokenB != "MAAL") {
        const isAllowed = await Allow(user, tokenBaddress, ROUTERADDRESS);
        if (Number(isAllowed) <= 0) {
          setApprove2Symbol(tokenB);
          setApprove2Token(tokenBaddress);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectToken1 = async (address, symbol, decimal, logo) => {
    try {
      setToken1(address);
      setToken1Symbol(symbol);
      setToken1Decemal(decimal);
      setToken1Logo(logo);
      // setToken1Name(Native.name)
      if (symbol == "MAAL") {
        const nativeBalance = await userBalanceOfChain(user);
        setToken1Balance(nativeBalance);
      } else {
        const nativeBalance = await get_Token_Balance(address, user);
        setToken1Balance(nativeBalance);
      }
      setselect1s(true);
      setToken1Amount(0);
      setToken2Amount(0);
    } catch (error) {
      console.log(error);
    }
  };

  const selectTokenthatisnotnavtive = async (
    address,
    symbol,
    decimal,
    logo
  ) => {
    try {
      setToken2(address);
      setToken2Symbol(symbol);
      setToken2Decemal(decimal);
      setToken2Logo(logo);
      // setToken2Name(Native.name)
      if (symbol == "MAAL") {
        const nativeBalance = await userBalanceOfChain(user);
        setToken2Balance(nativeBalance);
      } else {
        const nativeBalance = await get_Token_Balance(address, user);
        setToken2Balance(nativeBalance);
      }
      setselect2s(true);
      setToken1Amount(0);
      setToken2Amount(0);
    } catch (error) {
      console.log(error);
    }
  };

  const token1set = async (value) => {
    if (value == undefined || value == 0) {
      setToken1Amount(0);
      setToken2Amount(0);
      return;
    }
    setToken1Amount(value);
    if (invalidPair || !haveliquidity) {
      return;
    }
    const a = await towie(value);
    const reserveIn = await towie(token1liq);
    const reserveOut = await towie(token2liq);
    // const amountOut = await routerContract.methods.getAmountOut(a, reserveIn, reserveOut).call();
    // const amointIn = await routerContract.methods.getAmountIn(amountOut, reserveIn, reserveOut).call();
    const amountOut = await routerContract.methods
      .quote(a, reserveIn, reserveOut)
      .call();
    const amointIn = await routerContract.methods
      .quote(amountOut, reserveOut, reserveIn)
      .call();
    console.log("Quote", amountOut);
    setAmountIn(amointIn);
    setAmountOut(amountOut);
    setToken2Amount(Number(amountOut) / 10 ** token2decimal);
  };

  const token2set = async (value) => {
    if (value == undefined || value == 0) {
      setToken1Amount(0);
      setToken2Amount(0);
      return;
    }
    setToken2Amount(value);
    if (invalidPair || !haveliquidity) {
      return;
    }
    const b = await towie(value);
    const reserveIn = await towie(token1liq);
    const reserveOut = await towie(token2liq);
    // const amointIn = await routerContract.methods.getAmountIn(b, reserveIn, reserveOut).call();
    // const amountOut = await routerContract.methods.getAmountOut(amointIn, reserveIn, reserveOut).call();
    const amointIn = await routerContract.methods
      .quote(b, reserveOut, reserveIn)
      .call();
    const amountOut = await routerContract.methods
      .quote(amointIn, reserveIn, reserveOut)
      .call();
    setAmountOut(amountOut);
    setAmountIn(amointIn);
    setToken1Amount(Number(amointIn) / 10 ** token1decimal);
  };

  const Minpercent = async (amount, decimal) => {
    const normal = await fromwie(amount);
    const min = normal * 0.01;
    const minwei = await towie(min.toFixed(decimal));
    return minwei;
  };

  const AddingLiqudity = async () => {
    try {
      const deadlinetime = Number(
        (new Date().getTime() + 1200000) / 1000
      ).toFixed(0);
      const a = await towie(token1amount);
      const b = await towie(token2amount);

      // if its true it means pair does not exit so it creates new pair
      if (invalidPair) {
        setOpen(true);
        const fromBC = await factoryContract.methods
          .createPair(token1, token2)
          .send({ from: user });
        if (fromBC.status) {
          notify("Success");
          setOpen(false);
        }
      } else if (!haveliquidity) {
        console.log("Adding fresh liqidity");
        if (token1Symbol === "MAAL") {
          setOpen(true);
          const swapdata = await routerContract.methods
            .addLiquidityMAAL(token2, b, b, a, user, deadlinetime)
            .send({ from: user, value: a });
          if (swapdata.status) {
            notify("Success");
            setOpen(false);
          }
        } else if (token2Symbol === "MAAL") {
          setOpen(true);
          const swapdata = await routerContract.methods
            .addLiquidityMAAL(token1, a, a, b, user, deadlinetime)
            .send({ from: user, value: b });
          if (swapdata.status) {
            notify("Success");
            setOpen(false);
          }
        } else {
          setOpen(true);
          console.log("Adding token to token LP");
          const swapdata = await routerContract.methods
            .addLiquidity(token1, token2, a, b, a, b, user, deadlinetime)
            .send({ from: user });
          if (swapdata.status) {
            notify("Success");
            setOpen(false);
          }
        }
      } else if (token1Symbol === "MAAL") {
        setOpen(true);
        const mintokenAmount = await Minpercent(amountout, token2decimal);
        const minMaalAmount = await Minpercent(a, 18);
        const swapdata = await routerContract.methods
          .addLiquidityMAAL(
            token2,
            amountout,
            mintokenAmount,
            minMaalAmount,
            user,
            deadlinetime
          )
          .send({ from: user, value: a });
        if (swapdata.status) {
          notify("Success");
          setOpen(false);
        }
      } else if (token2Symbol === "MAAL") {
        setOpen(true);
        const mintokenAmount = await Minpercent(amountin, token1decimal);
        const minMaalAmount = await Minpercent(b, 18);
        const swapdata = await routerContract.methods
          .addLiquidityMAAL(
            token1,
            amountin,
            mintokenAmount,
            minMaalAmount,
            user,
            deadlinetime
          )
          .send({ from: user, value: b });
        if (swapdata.status) {
          notify("Success");
          setOpen(false);
        }
      } else {
        setOpen(true);
        console.log("Adding more and token to token LP");
        const mintokenA = await Minpercent(a, token1decimal);
        const mintokenB = await Minpercent(b, token2decimal);
        const swapdata = await routerContract.methods
          .addLiquidity(
            token1,
            token2,
            a,
            b,
            mintokenA,
            mintokenB,
            user,
            deadlinetime
          )
          .send({ from: user });
        if (swapdata.status) {
          notify("Success");
          setOpen(false);
        }
      }
    } catch (error) {
      // console.log(error)
      setOpen(false);
    }
  };

  const Approvetoken = async (token) => {
    try {
      setOpen(true);
      const data = await Approve(ROUTERADDRESS, token);
      if (data.status) {
        notify("Approved");
        setOpen(false);
        isAllow(token1Symbol, token2Symbol, token1, token2);
      }
    } catch (error) {
      setOpen(false);
    }
  };

  const Statementshow = () => {
    if (user) {
      if (invalidPair) {
        return "Create pair";
      } else {
        if (token1amount > token1Balance) {
          return `Insufficient ${token1Symbol} balance`;
        } else if (token2amount > token2Balance) {
          return `Insufficient ${token2Symbol} balance`;
        }
        if (select1 && select2 && token1amount > 0 && token2amount > 0) {
          return "Add Liquidity";
        } else {
          return "Enter Amount";
        }
      }
    } else {
      return (
        <div className="" onClick={handleShow_wallet}>
          Connect wallet
        </div>
      );
    }
  };

  const searchForToken = async (address) => {
    const index = ERC_TOKEN.findIndex(
      (item) => convertToLowercase(item.address) === convertToLowercase(address)
    );
    if (index == -1) {
      if (isEthereumAddress(address)) {
        const contract = await get_ERC_20_Contract(address);
        const name = await contract.methods.name().call();
        const symbol = await contract.methods.symbol().call();
        const decimal = await contract.methods.decimals().call();
        setERC20Tokens([
          {
            address: address,
            name: name,
            symbol: symbol,
            logo: unknownLogo,
            decimal: decimal,
          },
        ]);
      } else {
        setERC20Tokens([]);
      }
    } else {
      setERC20Tokens([
        {
          address: ERC_TOKEN[index].address,
          name: ERC_TOKEN[index].name,
          symbol: ERC_TOKEN[index].symbol,
          logo: ERC_TOKEN[index].logo,
          decimal: ERC_TOKEN[index].decimal,
        },
      ]);
    }
  };

  return (
    <>
      <div className="background_image t-a-c">
        <div className="container-kws">
          <div className="m-t-3 m-b-4 d-b max-w-40rem m-a ">
            <div className="bg_box_clr b-r-15  pa-1_5">
              <div className="d-f j-c-s-b a-i-c m-b-1">
                <Link to="/pool">
                  {" "}
                  <div className="f-w-600 o_clr f-s-1_25">
                    <AiOutlineArrowLeft className="f_o_clr" />
                  </div>
                </Link>{" "}
                <div className="o_clr f-s-1_25">Add Liquidity</div>
                <div className="txt_clr f-s-1_25">
                  <AiOutlineQuestionCircle className="f_gray_clr" />
                </div>
              </div>
              <div className="bg_box_clr pa-1 b-r-15 ">
                <div className="d-f a-i-c j-c-s-b m-b-1">
                  <div className="gray_clr">Input</div>
                  <div className="gray_clr">Balance : {token1Balance}</div>
                </div>
                <div className="d-f a-i-c j-c-s-b">
                  <div className="">
                    <input
                      type="text"
                      className="b-n f-s-1_5 b-c-t o_clr "
                      placeholder="0.0"
                      value={token1amount}
                      onChange={(e) => token1set(e.target.value)}
                    />
                  </div>
                  <div className="d-f a-i-c">
                    <div className="">
                      <div className="d-f a-i-c c-p o_clr f-w-600 w-s-n-w">
                        <div className="">
                          <img
                            src={token1logo}
                            className="w-2 h-2 b-r-50 m-r-0_5"
                          />
                        </div>
                        <div className="ps-r">
                          <div className="" onClick={handleShow6}>
                            <span className="m-r-1">{token1Symbol}</span>
                            <MdKeyboardArrowDown className="f_o_clr b-c-t  f-s-1_5" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="f_gray_clr f-s-1_5">+</div>
              </div>

              <div className="bg_box_clr pa-1 b-r-15 m-b-1">
                <div className="d-f a-i-c j-c-s-b m-b-1">
                  <div className="gray_clr">Input</div>
                  {select2 ? (
                    <div className="gray_clr">Balance : {token2Balance}</div>
                  ) : (
                    <div className="gray_clr">_</div>
                  )}
                </div>
                <div className="d-f a-i-c j-c-s-b">
                  <div className="">
                    <input
                      type="text"
                      className="b-n f-s-1_5 b-c-t o_clr "
                      placeholder="0.0"
                      value={token2amount}
                      onChange={(e) => token2set(e.target.value)}
                    />
                  </div>
                  <div className="d-f a-i-c c-p">
                    <div className="">
                      {!select2 ? (
                        <div className="d-f a-i-c bg_o_clr pa-5px b-r-10  f-w-600 w-s-n-w">
                          <div className="ps-r">
                            <div className="" onClick={handleShow7}>
                              <span className=" max_clr w-s-n-w">
                                Select a token &nbsp;{" "}
                                <MdKeyboardArrowDown className=" b-c-t f_max_clr  f-s-1_5" />
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="d-f a-i-c bg_o_clr pa-5px b-r-10  f-w-600 w-s-n-w">
                            <div className="m-r-0_5">
                              <img
                                src={token2logo}
                                className="w-2 h-2 b-r-50 m-r-0_5"
                              />
                            </div>
                            <div className="" onClick={handleShow7}>
                              <span className="max_clr w-s-n-w">
                                {token2Symbol}
                              </span>
                              <MdKeyboardArrowDown
                                size={22}
                                className=" b-c-t f_max_clr  f-s-1_5"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* {select1 && select2 ? (
                <div className="d-f bg_box_clr m-b-1 b-r-15 pa-1  a-i-c j-c-s-b t-a-c">
                  <div className="">
                    <span className="o_clr">-</span> <br />
                    ART per BNB
                  </div>
                  <div className="">
                    <span className="o_clr">-</span>
                    <br />
                    BNB per ART
                  </div>
                  <div className="">
                    {" "}
                    <span className="o_clr">0 %</span> <br />
                    Share of
                  </div>
                </div>
              ) : (
                ""
              )} */}

              <div className="">
                {!approve1symbol && !approve2symbol ? (
                  <button
                    className="b-c-t p-y-1 w-100 bg_btn_clr btn_clr f-s-1_5 f-w-600 b-r-15 b-n"
                    onClick={() => {
                      if (user) {
                        AddingLiqudity();
                      }
                    }}
                  >
                    {Statementshow()}
                  </button>
                ) : (
                  ""
                )}

                {user && approve1symbol ? (
                  <button
                    className="b-c-t p-y-1 w-100 bg_btn_clr btn_clr f-s-1_5 f-w-600 b-r-15 b-n"
                    onClick={() => Approvetoken(approve1token)}
                  >
                    {`Approve ${approve1symbol}`}
                  </button>
                ) : (
                  ""
                )}

                {user && approve2symbol ? (
                  <button
                    className="b-c-t p-y-1 w-100 bg_btn_clr btn_clr f-s-1_5 f-w-600 b-r-15 b-n m-t-1"
                    onClick={() => Approvetoken(approve2token)}
                  >
                    {`Approve ${approve2symbol}`}
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <Modal show={show3} onHide={handleClose3} className="b-n  o-n ">
        <Modal.Body className="b-n o-n bg_box_clr o_clr b-r-15">
          <div className="p-y-1">
            <div className="d-f a-i-c j-c-s-b m-b-1 o_clr  b-r-15">
              <div className="f-s-1_25">You are creating a pool</div>
              <div className="">
                {" "}
                <RxCross2
                  className="f-s-1_5 c-p f_o_clr o_clr"
                  onClick={handleClose3}
                />{" "}
              </div>
            </div>
            <div className="f-s-2_5 f-w-600 p-y-2 t-a-c">CHOOKY / ART</div>
            <div className="">
              <div className="d-f a-i-c j-c-s-b f-s-1_25 m-y-1">
                <div className="">CHOOKY Deposited</div>
                <div className="">
                  <AiOutlineQuestionCircle /> &nbsp; 9000
                </div>
              </div>
              <div className="d-f a-i-c j-c-s-b f-s-1_25 m-y-1">
                <div className="">ART Deposited</div>
                <div className="">
                  <AiOutlineQuestionCircle /> &nbsp; 800016000
                </div>
              </div>
              <div className="d-f a-i-c j-c-s-b f-s-1_25 m-y-1">
                <div className="">Rates</div>
                <div className="t-a-r">
                  {" "}
                  &nbsp; 1 CHOOKY = 8889 ART <br /> 1 ART = 0.0001125 CHOOKY
                </div>
              </div>
              <div className="d-f a-i-c j-c-s-b f-s-1_25 m-y-1">
                <div className="">Share of Pool :</div>
                <div className="">100%</div>
              </div>
              <div className="">
                <button className="b-c-t p-y-1 w-100 bg_o_clr  f-s-1_5 f-w-600 max_clr b-r-15 b-n">
                  Create Pool & Supply
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show4} onHide={handleClose4} className="b-n  o-n ">
        <Modal.Body className="b-n o-n bg_box_clr o_clr b-r-15">
          <div className="p-y-1">
            <div className="d-f a-i-c j-c-s-b m-b-1 o_clr  b-r-15">
              <div className="f-s-1_25"></div>
              <div className="">
                {" "}
                <RxCross2
                  className="f-s-1_5 c-p f_o_clr o_clr"
                  onClick={handleClose4}
                />{" "}
              </div>
            </div>
            <div className="f-s-2_5  p-y-2 t-a-c">
              <BsArrowUpCircle className="f-s-7 f_o_clr" />
            </div>
            <div className="f-s-1_5  t-a-c m-y-2">Transaction Submitted</div>
            <div className="  t-a-c m-y-2">View on Bscscan</div>
            <div className="">
              <button className="b-c-t p-y-1 w-100 bg_o_clr  f-s-1_5 f-w-600 max_clr b-r-15 b-n">
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show5} onHide={handleClose5} className="b-n  o-n ">
        <Modal.Body className="b-n o-n bg_box_clr o_clr b-r-15">
          <div className="p-y-1">
            <div className="f-s-2_5  p-y-2 t-a-c">
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
              />
            </div>
            <div className="f-s-1_5  t-a-c m-y-2">Waiting For Confirmation</div>
            <div className="  t-a-c m-y-2">
              Supplying 0.001 BNB and 800016000 ART
            </div>
            <div className=" gray_clr t-a-c m-y-2 f-s-0_8">
              Confirm this transaction in your wallet
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show6} onHide={handleClose6} className="b-n  o-n ">
        <Modal.Body className="b-n o-n bg_box_clr b-r-15">
          <div className="p-y-1">
            <div className="d-f a-i-c j-c-s-b m-b-1 o_clr  b-r-15">
              <div className="f-s-1_25">
                Select a token{" "}
                <AiOutlineQuestionCircle className="f-s-1_5 m-l-0_5  f_gray_clr" />
              </div>
              <div className="">
                {" "}
                <RxCross2
                  className="f-s-1_5 c-p f_o_clr o_clr"
                  onClick={handleClose6}
                />{" "}
              </div>
            </div>
            <div className="m-b-1">
              <input
                type="text"
                className="input search b-1 pa-0_5 b-c-t b-r-10 w-100 o_clr f-s-1_25 "
                onChange={(e) => searchForToken(e.target.value)}
                placeholder="Search address"
              />
            </div>
            <div className="o_clr m-b-1">Token Name</div>
            {erc20Tokens.map((res) => {
              return (
                <div
                  className="d-f o_clr a-i-c j-c-s-b m-y-1 c-p"
                  onClick={() => {
                    selectToken1(
                      res.address,
                      res.symbol,
                      res.decimal,
                      res.logo
                    );
                    handleClose6();
                  }}
                >
                  <div className="d-f a-i-c">
                    <div className="">
                      <img src={res.logo} className="w-2 h-2 b-r-50 m-r-0_5" />{" "}
                    </div>
                    <div className="">{res.symbol}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show7} onHide={handleClose7} className="b-n  o-n ">
        <Modal.Body className="b-n o-n bg_box_clr b-r-15">
          <div className="p-y-1">
            <div className="d-f a-i-c j-c-s-b m-b-1 o_clr  b-r-15">
              <div className="f-s-1_25">
                Select a token{" "}
                <AiOutlineQuestionCircle className="f-s-1_5 m-l-0_5  f_gray_clr" />
              </div>
              <div className="">
                {" "}
                <RxCross2
                  className="f-s-1_5 c-p f_o_clr o_clr"
                  onClick={handleClose7}
                />{" "}
              </div>
            </div>
            <div className="m-b-1">
              <input
                type="text"
                className="input search b-1 pa-0_5 b-c-t b-r-10 w-100 o_clr f-s-1_25 "
                onChange={(e) => searchForToken(e.target.value)}
                placeholder="Search address"
              />
            </div>
            <div className="o_clr m-b-1">Token Name</div>
            {erc20Tokens.map((res) => {
              return (
                <div
                  className="d-f o_clr a-i-c j-c-s-b m-y-1 c-p"
                  onClick={() => {
                    selectTokenthatisnotnavtive(
                      res.address,
                      res.symbol,
                      res.decimal,
                      res.logo
                    );
                    handleClose7();
                  }}
                >
                  <div className="d-f a-i-c">
                    <div className="">
                      <img src={res.logo} className="w-2 h-2 b-r-50 m-r-0_5" />{" "}
                    </div>
                    <div className="">{res.symbol}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
      {/* ---------------mobile-navbar---------------------------------- */}

      <Offcanvas
        show={show_wallet}
        onHide={handleClose_wallet}
        className="pa-1 bg_btn_clr gray_clr f-s-1_25"
        placement="end"
        name="end"
      >
        <Offcanvas.Body className=" ">
          <div className="d-f a-i-c j-c-s-b m-b-2">
            {" "}
            <div className="o_clr"> Connect a wallet</div>
            <div className="">
              {" "}
              <RxCross2
                className="f-s-1_5 c-p f_o_clr o_clr "
                onClick={handleClose_wallet}
              />
            </div>
          </div>

          <div
            className="m-y-1 c-p w-100 bg_o_clr max_clr b-r-15 d-f pa-1 a-i-c"
            onClick={() => {
              Metamask();
              handleClose_wallet();
            }}
          >
            <div className="">
              <img src={meta} alt="" className="m-r-1 w-2" />{" "}
            </div>
            <div className="">Metamask</div>
          </div>
          <div
            className="m-y-1 c-p w-100 bg_o_clr max_clr b-r-15 d-f pa-1 a-i-c"
            onClick={() => {
              WalletC();
              handleClose_wallet();
            }}
          >
            <div className="">
              <img src={Wallet} alt="" className="m-r-1 w-2" />{" "}
            </div>
            <div className="">Wallet connect</div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Add_liquidity;
