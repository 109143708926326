import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import My_lock_tab from "./My_lock_tab";
import { useStoreState } from "easy-peasy";
import {
  convertToLowercase,
  get_ERC_20_Contract,
  get_Locker_Contract,
  get_PAIR_Contract,
} from "../../Web3/web3";
import { ERC_TOKEN } from "./../../Web3/Tokens";
import isEthereumAddress from "validator/lib/isEthereumAddress";
import Footer from "./Footer";

function My_lock() {
  const user = useStoreState((state) => state.user);
  const [userlps, setUserLps] = useState([]);
  const [mappingLP, setMappingLP] = useState([]);

  useEffect(() => {
    const init = async () => {
      const lpContract = await get_Locker_Contract();
      const lps = await lpContract.methods.lpLocksForUser(user).call();
      const allLps = [];
      for (let i = 0; i < lps.length; i++) {
        // console.log("Lp details", lps[i]);

        const lpDetails = {
          // Copy existing properties from the array element
          ...lps[i],
        };

        const pairContract = await get_PAIR_Contract(lps[i][1]);
        const token0 = await pairContract.methods.token0().call();
        const token1 = await pairContract.methods.token1().call();
        const symbol = await pairContract.methods.symbol().call();
        const token0Index = ERC_TOKEN.findIndex(
          (item) => item.address === token0
        );
        const token1Index = ERC_TOKEN.findIndex(
          (item) => item.address === token1
        );

        if (token0Index < 0 && token1Index > 0) {
          const tokenContract = await get_ERC_20_Contract(token0);
          const name = await tokenContract.methods.name().call();
          const token2Details = ERC_TOKEN[token1Index];
          lpDetails[12] = name;
          lpDetails[13] = "";
          lpDetails[14] = token2Details.name;
          lpDetails[15] = token2Details.logo;
          lpDetails[16] = symbol;
        }
        if (token0Index > 0 && token1Index < 0) {
          const token1Details = ERC_TOKEN[token0Index];
          const tokenContract = await get_ERC_20_Contract(token1);
          const name = await tokenContract.methods.name().call();
          lpDetails[12] = token1Details.name;
          lpDetails[13] = token1Details.logo;
          lpDetails[14] = name;
          lpDetails[15] = "";
          lpDetails[16] = symbol;
        } else {
          const token2Details = ERC_TOKEN[token1Index];
          const token1Details = ERC_TOKEN[token0Index];
          lpDetails[12] = token1Details.name;
          lpDetails[13] = token1Details.logo;
          lpDetails[14] = token2Details.name;
          lpDetails[15] = token2Details.logo;
          lpDetails[16] = symbol;
          // Assign the modified object back to the array
          allLps.push(lpDetails);
        }
        // Add new properties to the object
        console.log(lpDetails);
      }
      setUserLps(allLps);
      setMappingLP(allLps);
    };

    init();
  }, [user]);

  const search = async (address) => {
    try {
      if (address == "") {
        setUserLps(mappingLP);
        return;
      }
      const valid = isEthereumAddress(
        address === "0x0000000000000000000000000000000000000000" ? "" : address
      );
      if (valid) {
        const result = userlps.filter(function (element) {
          return (
            convertToLowercase(element[1]) == convertToLowercase(address) ||
            convertToLowercase(element[2]) == convertToLowercase(address)
          );
        });
        setUserLps(result);
      } else {
        // notify("Invalid address")
        setUserLps([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className=" background_image ">
        <div className="container-kws">
          <div className="bg_br_clr  b-r-15 m-t-4 m-b-5  pa-1_5">
            <My_lock_tab />
            <div className="m-t-2">
              <input
                type="text"
                className="input w-100 b-r-15 m-b-2 pa-0_5 b-c-t"
                onChange={(e) => search(e.target.value)}
                placeholder="Search by token address..."
              />
            </div>
            <Paper
              sx={{
                width: "100%",
                overflow: "hidden",
                background: "transparent",
              }}
              className="m-t-2"
            >
              <TableContainer sx={{ maxHeight: 440, boxShadow: "none" }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{ boxShadow: "none" }}
                >
                  <TableHead className="f-w-600">
                    <TableRow className="f-w-600">
                      <TableCell className="f-w-600 o_clr">Token</TableCell>
                      <TableCell className="f-w-600 o_clr">Amount</TableCell>
                      <TableCell className="f-w-600 o_clr">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {typeof userlps === "object" &&
                      userlps.map((res) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1}>
                            <TableCell>
                              <div className="d-f a-i-c">
                                <div className="m-r-1">
                                  {" "}
                                  <img src={res[13]} alt="" className="w-2" />
                                </div>
                                <div className="c-w">
                                  <span className="f-w-600 o_clr">
                                    {" "}
                                    {res[12]}{" "}
                                  </span>{" "}
                                  <br />
                                  {res[14]}
                                </div>
                              </div>
                              &nbsp;{" "}
                            </TableCell>
                            <TableCell className="c-w">
                              {Number(res[3]) / 10 ** 18} {res[16]}
                            </TableCell>
                            <TableCell>
                              {" "}
                              <Link
                                to={`/My_liquidity_lock_detail/${userlps.indexOf(
                                  res
                                )}/${res[1]}`}
                              >
                                <button className="pa-0_5 c-i b-c-i b-r-10 f-s-1_25 p-x-1 bg_o_clr f-w-600 max_clr b-n btn btn-primary">
                                  View
                                </button>{" "}
                              </Link>{" "}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                {userlps && userlps.length == 0 ? (
                  <div className="p-y-5  t-a-c c-w">No data to show...</div>
                ) : (
                  ""
                )}
              </TableContainer>
            </Paper>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default My_lock;
