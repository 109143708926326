import { initializeConnector } from '@web3-react/core'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'


export const [walletConnectV2, hooks] = initializeConnector(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId:'b9bd638e50d4ce444f33b0499cac8433',
        chains: [880],
        showQrModal: true,
      },
    })
)
