import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AiFillEdit, AiOutlineCopy } from "react-icons/ai";
import {
  convertToLowercase,
  fromwie,
  get_ERC_20_Contract,
  get_Locker_Contract,
  get_Maal_Locker_Contract,
  towie,
} from "../../Web3/web3";
import { useStoreState } from "easy-peasy";
import Countdown from "react-countdown";
import { Modal } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import { DatePicker } from "antd";
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from "@mui/material";
import Footer from "./Footer";

const notify = (msg) =>
  toast.success(msg, {
    position: toast.POSITION.TOP_RIGHT,
  });

function My_lock() {
  const { address } = useParams();
  const user = useStoreState((state) => state.user);
  const [open, setOpen] = useState();
  const [tokenName, setTokenName] = useState();
  const [tokenSymbol, setTokenSymbol] = useState();
  const [claimbal, setClaimbal] = useState(0);
  const [totalbal, setTotalbal] = useState(0);
  const [owner, setoOwner] = useState("");
  const [vestingDuration, setVestingDuration] = useState(0);
  const [percentPerClaim, setPercentPerClaim] = useState(0);
  const [numberOfVestingPeriods, setNumberOfVestingPeriods] = useState(0);
  const [lockExpired, setLockExpired] = useState(0);
  const [ending, setEnding] = useState();
  const [amount, setAmount] = useState();

  useEffect(() => {
    const init = async () => {
      const lockerContrat = await get_Maal_Locker_Contract(address);
      const claimbal = await lockerContrat.methods.claimedBalance().call();
      setClaimbal(await fromwie(claimbal));
      const totalbal = await lockerContrat.methods.totalBalance().call();
      setTotalbal(await fromwie(totalbal));
      const owner = await lockerContrat.methods.owner().call();
      setoOwner(owner);
      const vestingDuration = await lockerContrat.methods
        .vestingPeriodDuration()
        .call();
      setVestingDuration(vestingDuration);
      const percentPerClaim = await lockerContrat.methods
        .percentPerClaim()
        .call();
      setPercentPerClaim(percentPerClaim);
      const numberOfVestingPeriods = await lockerContrat.methods
        .numberOfVestingPeriods()
        .call();
      setNumberOfVestingPeriods(numberOfVestingPeriods);
      const lockExpired = await lockerContrat.methods.lockExpired().call();
      setLockExpired(lockExpired);

      const creationTime = await lockerContrat.methods.creationTime().call();
      const startWaitTime = await lockerContrat.methods.startWaitTime().call();

      const ending =
        Number(creationTime) +
        Number(startWaitTime) +
        Number(vestingDuration) * numberOfVestingPeriods;
      setEnding(ending);
      console.log("Ending at", ending);
    };
    init();
  }, [user, open]);

  const sliceString = (str) => {
    return str?.length > 25 ? `${str.substring(0, 6)}...${str.substr(36)}` : ``;
  };

  const DepositMaal = async (address, amount) => {
    try {
      setOpen(true);
      const lockerContrat = await get_Maal_Locker_Contract(address);
      const a = await towie(amount);
      const data = await lockerContrat.methods
        .deposit()
        .send({ from: user, value: a });
      if (data.status) {
        notify("Token Locked");
        setOpen(false);
      }
    } catch (error) {
      console.log(error);
      setOpen(false);
    }
  };

  const claimMaal = async (address) => {
    try {
      setOpen(true);
      const lockerContrat = await get_Maal_Locker_Contract(address);
      // const data = await lockerContrat.methods.claim().call();
      // console.log('data', data)
      const data = await lockerContrat.methods.claim().send({ from: user });
      console.log(data);
      if (data.status) {
        notify("Claimed success");
        setOpen(false);
      }
    } catch (error) {
      console.log(error);
      setOpen(false);
    }
  };

  return (
    <div>
      <div className=" background_image ">
        <div className="container-kws m-b-7">
          <div className="max-w-50 d-b m-a">
            <div className="bg_br_clr  b-r-15 m-t-4   pa-1_5">
              <div className="m-b-1 f-w-600 t-a-c">Expire in</div>
              <div className="d-f a-i-c j-c-c">
                <div className="pa-0_5 m-r-0_5 b-r-5 bg_o_clr max_clr">
                  {" "}
                  {ending && Number(ending) > new Date().getTime() / 1000 ? (
                    <Countdown date={Number(ending) * 1000} />
                  ) : (
                    "00:00:00"
                  )}
                </div>
              </div>
            </div>
            <div className="bg_br_clr  b-r-15 m-t-2   pa-1_5">
              <div className="f-s-1_25 f-w-600">Locker info</div>
              <hr />
              <div className="d-f a-i-c j-c-s-b m-y-1">
                <div className="">Contract Address</div>
                <div className="o_clr">
                  {sliceString(address)}{" "}
                  <AiOutlineCopy className="f_o_clr c-p" onClick={()=>navigator.clipboard.writeText(address).then((res)=>{
                    notify("Copied")
                  })}/>
                </div>
              </div>
              <div className="d-f a-i-c j-c-s-b m-y-1">
                <div className="">Owner Address</div>
                <div className="o_clr">
                  {sliceString(owner)} <AiOutlineCopy className="f_o_clr c-p" onClick={()=>navigator.clipboard.writeText(owner).then((res)=>{
                    notify("Copied")
                  })}/>
                </div>
              </div>
              <div className="d-f a-i-c j-c-s-b m-y-1">
                <div className="">Total Balance</div>
                <div className="">{totalbal} MAAL</div>
              </div>
              <div className="d-f a-i-c j-c-s-b m-y-1">
                <div className="">Claimed Balance</div>
                <div className="o_clr">{claimbal} MAAL</div>
              </div>
              <div className="d-f a-i-c j-c-s-b m-y-1">
                <div className="">Remaining Balace</div>
                <div className="o_clr">
                  {Number(totalbal) - Number(claimbal)} MAAL
                </div>
              </div>
              {vestingDuration == 0 ? (
                ""
              ) : (
                <div className="d-f a-i-c j-c-s-b m-y-1">
                  <div className="">Vesting Duration</div>
                  <div className="o_clr">
                    {(Number(vestingDuration) / 86400).toFixed(2)} days
                  </div>
                </div>
              )}
              <div className="d-f a-i-c j-c-s-b m-y-1">
                <div className="">Percent Per Claim</div>
                <div className="o_clr">{percentPerClaim} %</div>
              </div>
              <div className="d-f a-i-c j-c-s-b m-y-1">
                <div className="">Number Of Vesting Periods</div>
                <div className="o_clr">{numberOfVestingPeriods}</div>
              </div>
              <div className="d-f a-i-c j-c-s-b m-y-1">
                <div className="">Lock Expired</div>
                <div className="o_clr">{`${lockExpired}`}</div>
              </div>
              {convertToLowercase(user) == convertToLowercase(owner) ? (
                <div>
                  <div className="d-f a-i-c j-c-c m-y-1">
                    <input
                      type="text"
                      className="w-100 b-1 b-r-15 m-r-1 pa-0_5 b-c-t c-w"
                      placeholder="amount"
                      onChange={(e) => setAmount(e.target.value)}
                    />{" "}
                    <button
                      className="b-c-t b-n w-100 bg_o_clr max_clr w-7 p-y-0_5 b-r-15"
                      onClick={() => DepositMaal(address, amount)}
                    >
                      Deposit
                    </button>
                  </div>
                  <div className=" m-t-2 m-b-1">
                    <button
                      className="b-c-t b-n bg_o_clr w-100 max_clr w-7 p-y-0_5 b-r-15"
                      onClick={() => claimMaal(address)}
                    >
                      Claim
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default My_lock;
