import { Backdrop, CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import Dropdown from "react-bootstrap/Dropdown";
import { MdKeyboardArrowDown } from "react-icons/md";
import bnb from "../Image/bnb.png";
import eth from "../Image/eth.png";
import { BiDownArrowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft, AiOutlineQuestionCircle } from "react-icons/ai";
import { RotatingLines } from "react-loader-spinner";
import { Modal } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import { BsArrowUpCircle } from "react-icons/bs";
import { ERC_TOKEN } from "../../Web3/Tokens";
import { useStoreState } from "easy-peasy";
import {
  Allow,
  Approve,
  fromwie,
  get_ERC_20_Contract,
  get_Factory_Contract,
  get_Router_Contract,
  get_Token_Balance,
  towie,
  userBalanceOfChain,
} from "../../Web3/web3";
import { ROUTERADDRESS } from "../../Web3/Contract";
import { toast } from "react-toastify";
import Footer from "./Footer";

const notify = (msg) =>
  toast.success(msg, {
    position: toast.POSITION.TOP_LEFT,
  });

function Home() {
  const [select1, setselect1s] = useState(false);
  const [select2, setselect2s] = useState(false);

  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);

  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);

  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [show7, setShow7] = useState(false);

  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const user = useStoreState((state) => state.user);
  const [token1, setToken1] = useState();
  const [token2, setToken2] = useState();
  const [token1Symbol, setToken1Symbol] = useState();
  const [token2Symbol, setToken2Symbol] = useState();
  const [token1Name, setToken1Name] = useState();
  const [token2Name, setToken2Name] = useState();
  const [open, setOpen] = useState(false);
  const [token1Balance, setToken1Balance] = useState();
  const [token1logo, setToken1Logo] = useState();
  const [token2logo, setToken2Logo] = useState();
  const [token2Balance, setToken2Balance] = useState();
  const [routerContract, setRouterContract] = useState();
  const [token1amount, setToken1Amount] = useState();
  const [token2amount, setToken2Amount] = useState();
  const [isTokenValuesSwaped, setIstokenValuedSwap] = useState(false);
  const [approve1symbol, setApprove1Symbol] = useState();
  const [approve2symbol, setApprove2Symbol] = useState();
  const [approve1token, setApprove1Token] = useState();
  const [approve2token, setApprove2Token] = useState();
  const [token1decimal, setToken1Decemal] = useState(0);
  const [token2decimal, setToken2Decemal] = useState(0);
  const [token1liq, setToken1Liq] = useState();
  const [token2liq, setToken2Liq] = useState();
  const [invalidPair, setIsInvalidPair] = useState(false);
  const [pairaddress, setPairAddress] = useState();
  const [factoryContract, setFactoryContract] = useState();
  const [haveliquidity, setHaveLiquidity] = useState(true);
  const [userPairBalance, setUserPiarBalance] = useState(false);
  const [tokenSwapRate, setTokenSwapRate] = useState(0);

  useEffect(() => {
    const init = async () => {
      const Native = ERC_TOKEN[0];
      setToken1(Native.address);
      setToken1Symbol(Native.symbol);
      setToken1Name(Native.name);
      setToken1Logo(Native.logo);
      setToken1Decemal(Native.decimal);
      setselect1s(true);
      // const nativeBalance = await userBalanceOfChain(user);
      // setToken1Balance(nativeBalance);
      // const router = await get_Router_Contract();
      // setRouterContract(router);
    };
    init();
  }, [user]);

  useEffect(() => {
    const init = async () => {
      try {
        const fac = await get_Factory_Contract();
        setFactoryContract(fac);
        const pair = await fac.methods.getPair(token1, token2).call();

        console.log("Pair", pair);

        if (pair === "0x0000000000000000000000000000000000000000") {
          setIsInvalidPair(true);
          return;
        } else {
          setIsInvalidPair(false);
          setPairAddress(pair);
        }

        const token1Contract = await get_ERC_20_Contract(token1);
        const token2Contract = await get_ERC_20_Contract(token2);

        const balanceOfPairInToken1 = await token1Contract.methods
          .balanceOf(pair)
          .call();
        const balanceOfPairInToken2 = await token2Contract.methods
          .balanceOf(pair)
          .call();

        const balanceOfPairInToken1InNumber =
          Number(balanceOfPairInToken1) / 10 ** token1decimal;
        const balanceOfPairInToken2InNumber =
          Number(balanceOfPairInToken2) / 10 ** token2decimal;

        if (
          balanceOfPairInToken1InNumber == 0 &&
          balanceOfPairInToken2InNumber == 0
        ) {
          setHaveLiquidity(false);
          return;
        }

        setToken1Liq(balanceOfPairInToken1InNumber);
        setToken2Liq(balanceOfPairInToken2InNumber);

        const router = await get_Router_Contract();
        const One = await towie(1);
        const tokenAPrice = await router.methods
          .quote(One, balanceOfPairInToken1, balanceOfPairInToken2)
          .call();
        const Ratenormal = await fromwie(tokenAPrice);
        setTokenSwapRate(Ratenormal);

        const pairContract = await get_ERC_20_Contract(pair);
        const userPairBalance = await pairContract.methods
          .balanceOf(user)
          .call();
        if (Number(userPairBalance) > 0) {
          setUserPiarBalance(true);
        } else {
          setUserPiarBalance(false);
        }
      } catch (error) {}
    };
    init();
  }, [token1, token2, user, open]);

  const selectToken1 = async (address, symbol, decimal, logo) => {
    try {
      setToken1(address);
      setToken1Symbol(symbol);
      setToken1Decemal(decimal);
      setToken1Logo(logo);
      // setToken1Name(Native.name)
      // const nativeBalance = await get_Token_Balance(address, user);
      setselect1s(true);
      // setToken1Balance(nativeBalance);
      setToken1Amount(0);
      setToken2Amount(0);
    } catch (error) {
      // console.log(error);
    }
  };

  const selectToken2 = async (address, symbol, decimal, logo) => {
    try {
      setToken2(address);
      setToken2Symbol(symbol);
      setToken2Decemal(decimal);
      setToken2Logo(logo);
      // setToken2Name(Native.name)
      // const nativeBalance = await get_Token_Balance(address, user);
      // setToken2Balance(nativeBalance);
      setselect2s(true);
      setToken1Amount(0);
      setToken2Amount(0);
    } catch (error) {
      // console.log(error);
    }
  };

  const StoreUserPoolDetails = (
    token1,
    token2,
    pair,
    token1logo,
    token2logo,
    decimalTokenA,
    decimalTokenB
  ) => {
    let userPoolDetailsArray =
      JSON.parse(localStorage.getItem("userPoolDetailsArray")) || [];
    const pairDetails = {
      token_A: token1,
      token_B: token2,
      pair: pair,
      token1logo: token1logo,
      token2logo: token2logo,
      decimalTokenA: decimalTokenA,
      decimalTokenB: decimalTokenB,
    };
    const objectExists = userPoolDetailsArray.some((item) => {
      return item.pair === pair;
    });

    if (!objectExists) {
      userPoolDetailsArray.push(pairDetails);
    }

    localStorage.setItem(
      "userPoolDetailsArray",
      JSON.stringify(userPoolDetailsArray)
    );
  };

  const Statementshow = () => {
    if (!select2) {
      return "Invalid pair";
    } else if (select1 && select2 && invalidPair) {
      return "Invalid pair";
    } else {
      if (select1 && select2 && token1liq > 0 && token2liq > 0) {
        if (user) {
          if (userPairBalance) {
            StoreUserPoolDetails(
              token1,
              token2,
              pairaddress,
              token1logo,
              token2logo,
              token1decimal,
              token2decimal
            );
            return "Pool found!";
          } else {
            return "You don't have liquidity in this pair";
          }
        } else {
          return "Connect Wallet";
        }
      } else {
        return "Pair does not have liquidity";
      }
    }
  };

  return (
    <>
      <div className="background_image t-a-c">
        <div className="container-kws">
          <div className="m-t-3 m-b-4 d-b max-w-40rem m-a ">
            <div className="bg_br_clr b-r-15  pa-1_5">
              <div className="d-f j-c-s-b a-i-c m-b-1">
                <Link to="/pool">
                  {" "}
                  <div className="f-w-600 o_clr f-s-1_25">
                    <AiOutlineArrowLeft className="f_o_clr" />
                  </div>
                </Link>{" "}
                <div className="o_clr f-s-1_25">Import Pool</div>
                <div className="txt_clr f-s-1_25">
                  <AiOutlineQuestionCircle className="f_gray_clr" />
                </div>
              </div>
              <div className="bg_box_clr pa-1 b-r-15 ">
                <div
                  className="d-f a-i-c j-c-s-b  c-p o_clr f-w-600 w-s-n-w"
                  onClick={handleShow6}
                >
                  <div className="d-f a-i-c">
                    <img src={token1logo} className="w-2 h-2 b-r-50 m-r-0_5" />
                    <div className="">
                      <span className="m-r-1">{token1Symbol}</span>
                    </div>
                  </div>
                  <div className=" ">
                    <div className="">
                      <MdKeyboardArrowDown className="f_o_clr b-c-t  f-s-1_25" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="f_gray_clr f-s-1_25">+</div>
              </div>

              <div className="bg_box_clr pa-1 b-r-15 m-b-1">
                <div
                  className="d-f a-i-c j-c-s-b  c-p o_clr f-w-600 w-s-n-w"
                  onClick={handleShow7}
                >
                  <div className="d-f a-i-c">
                    {token2logo ? (
                      <img
                        src={token2logo}
                        className="w-2 h-2 b-r-50 m-r-0_5"
                      />
                    ) : (
                      <img src={eth} className="w-2 h-2 b-r-50 m-r-0_5" />
                    )}
                    <div className="">
                      <span className="m-r-1">{token2Symbol || "Eth"}</span>
                    </div>
                  </div>
                  <div className=" ">
                    <div className="">
                      <MdKeyboardArrowDown className="f_o_clr b-c-t  f-s-1_25" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="t-a-c m-b-1">{Statementshow()}</div>
              {select1 && select2 && !invalidPair && haveliquidity ? (
                <>
                  <div className="t-a-c m-b-1"></div>
                  <div className="bg_box_clr pa-1 b-r-15 ">
                    <div className="t-a-l o_clr m-b-1">Your position</div>
                    <div className="d-f a-i-c j-c-s-b m-b-1 f-s-1_25 o_clr">
                      <div className="d-f a-i-c ">
                        <div className="m-r-0_5">
                          <img
                            src={token1logo}
                            className="w-1_5 h-1_5 b-1 b-r-50"
                          />
                        </div>
                        <div className="m-r-1">
                          <img
                            src={token2logo}
                            className="w-1_5 h-1_5 b-1 b-r-50"
                          />
                        </div>
                        <div className="t-t-u">
                          {token1Symbol}/{token2Symbol}
                        </div>
                      </div>
                      <div className="">{Number(tokenSwapRate).toFixed(3)}</div>
                    </div>
                    <div className="d-f a-i-c j-c-s-b m-b-1 f-s-1_25">
                      <div className="t-t-u">{token1Symbol} :</div>

                      <div className="">{Number(token1liq).toFixed(3)}</div>
                    </div>
                    <div className="d-f a-i-c j-c-s-b  f-s-1_25">
                      <div className="t-t-u">{token2Symbol} :</div>

                      <div className="">{Number(token2liq).toFixed(3)}</div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <Modal show={show3} onHide={handleClose3} className="b-n  o-n ">
        <Modal.Body className="b-n o-n bg_box_clr o_clr b-r-15">
          <div className="p-y-1">
            <div className="d-f a-i-c j-c-s-b m-b-1 o_clr  b-r-15">
              <div className="f-s-1_25">You are creating a pool</div>
              <div className="">
                {" "}
                <RxCross2
                  className="f-s-1_25 c-p f_o_clr o_clr"
                  onClick={handleClose3}
                />{" "}
              </div>
            </div>
            <div className="f-s-2_5 f-w-600 p-y-2 t-a-c">CHOOKY / ART</div>
            <div className="">
              <div className="d-f a-i-c j-c-s-b f-s-1_25 m-y-1">
                <div className="">CHOOKY Deposited</div>
                <div className="">
                  <AiOutlineQuestionCircle /> &nbsp; 9000
                </div>
              </div>
              <div className="d-f a-i-c j-c-s-b f-s-1_25 m-y-1">
                <div className="">ART Deposited</div>
                <div className="">
                  <AiOutlineQuestionCircle /> &nbsp; 800016000
                </div>
              </div>
              <div className="d-f a-i-c j-c-s-b f-s-1_25 m-y-1">
                <div className="">Rates</div>
                <div className="t-a-r">
                  {" "}
                  &nbsp; 1 CHOOKY = 8889 ART <br /> 1 ART = 0.0001125 CHOOKY
                </div>
              </div>
              <div className="d-f a-i-c j-c-s-b f-s-1_25 m-y-1">
                <div className="">Share of Pool :</div>
                <div className="">100%</div>
              </div>
              <div className="">
                <button className="b-c-t p-y-1 w-100 bg_o_clr  f-s-1_25 f-w-600 max_clr b-r-15 b-n">
                  Create Pool & Supply
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show4} onHide={handleClose4} className="b-n  o-n ">
        <Modal.Body className="b-n o-n bg_box_clr o_clr b-r-15">
          <div className="p-y-1">
            <div className="d-f a-i-c j-c-s-b m-b-1 o_clr  b-r-15">
              <div className="f-s-1_25"></div>
              <div className="">
                {" "}
                <RxCross2
                  className="f-s-1_25 c-p f_o_clr o_clr"
                  onClick={handleClose4}
                />{" "}
              </div>
            </div>
            <div className="f-s-2_5  p-y-2 t-a-c">
              <BsArrowUpCircle className="f-s-7 f_o_clr" />
            </div>
            <div className="f-s-1_25  t-a-c m-y-2">Transaction Submitted</div>
            <div className="  t-a-c m-y-2">View on Bscscan</div>
            <div className="">
              <button className="b-c-t p-y-1 w-100 bg_o_clr  f-s-1_25 f-w-600 max_clr b-r-15 b-n">
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show5} onHide={handleClose5} className="b-n  o-n ">
        <Modal.Body className="b-n o-n bg_box_clr o_clr b-r-15">
          <div className="p-y-1">
            <div className="f-s-2_5  p-y-2 t-a-c">
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
              />
            </div>
            <div className="f-s-1_25  t-a-c m-y-2">
              Waiting For Confirmation
            </div>
            <div className="  t-a-c m-y-2">
              Supplying 0.001 BNB and 800016000 ART
            </div>
            <div className=" gray_clr t-a-c m-y-2 f-s-0_8">
              Confirm this transaction in your wallet
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show6} onHide={handleClose6} className="b-n  o-n ">
        <Modal.Body className="b-n o-n bg_box_clr b-r-15">
          <div className="p-y-1">
            <div className="d-f a-i-c j-c-s-b m-b-1 o_clr  b-r-15">
              <div className="f-s-1_25">
                Select a token{" "}
                <AiOutlineQuestionCircle className="f-s-1_25 m-l-0_5  f_gray_clr" />
              </div>
              <div className="">
                {" "}
                <RxCross2
                  className="f-s-1_25 c-p f_o_clr o_clr"
                  onClick={handleClose6}
                />{" "}
              </div>
            </div>
            <div className="m-b-1">
              <input
                type="text"
                className="input search b-1 pa-0_5 b-c-t b-r-10 w-100 o_clr f-s-1_25 "
                placeholder="Search name or paste address"
              />
            </div>
            <div className="o_clr m-b-1">Token Name</div>
            {ERC_TOKEN.map((res) => {
              return (
                <div
                  className="d-f o_clr a-i-c j-c-s-b m-y-1 c-p"
                  onClick={() => {
                    selectToken1(
                      res.address,
                      res.symbol,
                      res.decimal,
                      res.logo
                    );
                    handleClose6();
                  }}
                >
                  <div className="d-f a-i-c">
                    <div className="">
                      <img src={res.logo} className="w-2 h-2 b-r-50 m-r-0_5" />{" "}
                    </div>
                    <div className="">{res.symbol}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show7} onHide={handleClose7} className="b-n  o-n ">
        <Modal.Body className="b-n o-n bg_box_clr b-r-15">
          <div className="p-y-1">
            <div className="d-f a-i-c j-c-s-b m-b-1 o_clr  b-r-15">
              <div className="f-s-1_25">
                Select a token{" "}
                <AiOutlineQuestionCircle className="f-s-1_25 m-l-0_5  f_gray_clr" />
              </div>
              <div className="">
                {" "}
                <RxCross2
                  className="f-s-1_25 c-p f_o_clr o_clr"
                  onClick={handleClose7}
                />{" "}
              </div>
            </div>
            <div className="m-b-1">
              <input
                type="text"
                className="input search b-1 pa-0_5 b-c-t b-r-10 w-100 o_clr f-s-1_25 "
                placeholder="Search name or paste address"
              />
            </div>
            <div className="o_clr m-b-1">Token Name</div>
            {ERC_TOKEN.map((res) => {
              return (
                <div
                  className="d-f o_clr a-i-c j-c-s-b m-y-1 c-p"
                  onClick={() => {
                    selectToken2(
                      res.address,
                      res.symbol,
                      res.decimal,
                      res.logo
                    );
                    handleClose7();
                  }}
                >
                  <div className="d-f a-i-c">
                    <div className="">
                      <img src={res.logo} className="w-2 h-2 b-r-50 m-r-0_5" />{" "}
                    </div>
                    <div className="">{res.symbol}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Home;
