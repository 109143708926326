import React from "react";
import ncrc from "../Image/ncrc.jpg";

function Footer() {
  return (
    <div>
      <div className="ps-f b-0 r-0 pa-2 z-i-_5">
        <a href="https://www.ncrics.com/" target="_blank">
          <div className="bg_box_clr p-x-1 p-y-0_5 b-r-10 d-f a-i-c">
            <div className="d-f a-s-f-e">Verified by</div>
            <div className="">
              <img src={ncrc} alt="" className="w-2_5 m-l-1 " />
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

export default Footer;
