import React, { useState } from "react";
import cyber from "../Image/cyber.png";
import { AiOutlineCopy } from "react-icons/ai";
import {
  Allow,
  Approve,
  fromwie,
  get_Locker_Contract,
  get_Maal_Factory_Locker_Contract,
  get_PAIR_Contract,
  towie,
} from "../../Web3/web3";
import { DatePicker, Space } from "antd";
import { useStoreState } from "easy-peasy";
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from "@mui/material";
import { LOCKER_ADDRESS } from "../../Web3/Contract";
import Footer from "./Footer";

const notify = (msg) =>
  toast.success(msg, {
    position: toast.POSITION.TOP_RIGHT,
  });

function Create_lock() {
  const [open, setOpen] = useState();

  const user = useStoreState((state) => state.user);
  const [tokenAddress, setTokanAddress] = useState();
  const [title, setTitle] = useState();
  const [amount, setAmount] = useState();
  const [lockTime, setLockTime] = useState();
  const [isLpToken, setIsLpToken] = useState(false);
  const [tokenBalance, setTokenBalance] = useState();
  const [tgapercent, setTGAPercent] = useState();
  const [cycleDays, setCycleDays] = useState();
  const [cyclepercent, setCyclePercent] = useState();
  const [isApprove, setIsApprove] = useState(false);
  const [symbol, setSymbol] = useState();

  const [clifftime, setCliffTime] = useState(0);
  const [vestingTime, setVestingTime] = useState(0);
  const [percent, setPercent] = useState(100);
  const [lock_time, setlock_times] = useState(false);

  const LockTokens = async () => {
    try {
      setOpen(true);
      const lpContract = await get_Maal_Factory_Locker_Contract();
      const time = 86400;
      const data = await lpContract.methods
        .createTimeLockedWallet(
          Number(clifftime) * time,
          Number(vestingTime) * time,
          percent
        )
        .send({ from: user });
      if (data.status) {
        notify("Lock created");
        setOpen(false);
        window.location.replace("/mylockdetail");
      }
    } catch (error) {
      setOpen(false);
      console.log(error);
    }
  };

  const setVasting = (tab) => {
    if (tab) {
      setlock_times(tab);
    } else {
      setVestingTime(0);
      setPercent(100);
      setlock_times(tab);
    }
  };

  return (
    <div>
      <div className=" background_image ">
        <div className="container-kws max-w-50 d-b m-a">
          <div className="">
            <div className="bg_br_clr m-t-4 m-b-5  b-r-15  pa-1_5">
              <div className="f-s-1_5 ">Create Maal Lock</div>
              <hr />
              {/* <div className="bg_btn_clr pa-1 b-r-15 m-b-2">
                <div className="m-b-1">PinkLock is audited by:</div>
                <div className="">
                  <img src={cyber} alt="" className=" w-13 b-r-15" />
                </div>
              </div> */}
              <div className="d-f a-i-c j-c-s-b m-b-1 m-t-2">
                <div className="">Cliff Time (in Days)</div>
              </div>
              <div className="m-b-2">
                <input
                  type="text"
                  onChange={(e) => setCliffTime(e.target.value)}
                  className="b-c-t w-100 b-r-15 pa-0_5 "
                />{" "}
              </div>
              <div className="d-f a-i-c m-b-1">
                <input
                  type="checkbox"
                  className="b-c-t  w-1 m-b-0 m-r-1"
                  onClick={() => setVasting(!lock_time)}
                />

                <div className="">use vesting?</div>
              </div>
              {lock_time ? (
                <>
                  {" "}
                  <div className="m-b-1">Vesting gap Time (in Days)</div>
                  <div className="m-b-2">
                    <input
                      type="text"
                      onChange={(e) => setVestingTime(e.target.value)}
                      className="b-c-t w-100 b-r-15 pa-0_5 "
                    />{" "}
                  </div>
                  <div className="m-b-1">Percent per claim</div>
                  <div className="m-b-1">
                    <input
                      type="text"
                      onChange={(e) => setPercent(e.target.value)}
                      className="b-c-t w-100 b-r-15 pa-0_5 "
                    />{" "}
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="m-b-1 t-a-c">
                <button
                  className="b-c-t b-n bg_o_clr max_clr p-x-2 p-y-0_5 b-r-15 m-t-1"
                  onClick={() => LockTokens()}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default Create_lock;
